import React, {useEffect, useRef, useState} from 'react'
import {
    Badge,
    Button,
    Card,
    Col,
    Form,
    FormControl,
    InputGroup,
    Modal,
    Pagination, Row,
    Spinner,
    Table
} from 'react-bootstrap'
import {useGlobalStore} from '../../store/globalContext'
import api_workorder from "../../api/workorder"
import WorkOrderDetails from '../work_order/WorkOrderDetails'
import moment from 'moment';
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {useForm} from "react-hook-form";
import api from "../../api/customer";
import luckydraw_api from "../../api/luckydraw";
import ModalConfirmWorkOrder from "../work_order/ModalConfirmWorkOrder";
import {LogoWithAccountNoSimple} from "../banks_logo";
import NewCustomerBonus from "./NewCustomerBonus";

export default function BonusCustomers () {
    const [ { dashboard_work_order_bonus_manual, login_profile }, dispatch ] = useGlobalStore();
    const [ validated, setValidated ] = useState( false );
    const [ startDate, setStartDate ] = useState( moment( new Date() ).format( "YYYY-MM-DD" ) );
    const [ refundFiles, setRefundFiles ] = useState( undefined );
    const [ workOrderDetailsData, setWorkOrderDetailsData ] = useState(
        {
            work_order_type: "bonus",
            work_order_sub_type: (process.env.REACT_APP_LUCKYDRAW_FLAG === "true")? "all" : "manual",
            cardHeader: "สรุปข้อมูล Bonus",
            total_work_order_today: 0,
            total_work_order_all: 0,
            total_sweep_withdraw_amount_today: 0,
            total_amount_today: 0
        } );
    //console.log(workOrderDetailsData)
    const [ dataTable, setDataTable ] = useState( [] )
    const [ retryInfo, setRetryInfo ] = useState( {} )
    const [ showRetryDialog, setShowRetryDialog ] = useState( false )

    const { register, handleSubmit, errors, setValue, reset } = useForm( {
        defaultValues: {},
        criteriaMode: "all"
    } );
    const [ customerSearchList, setCustomerSearchList ] = useState( [] )
    const [ isLoading, setIsLoading ] = useState( false );
    const [ confirmModal, setConfirmModal ] = useState( false )
    const [ confirmModalData, setConfirmModalData ] = useState(
        {
            user_id: "",
            first_name: "",
            last_name: "",
            amount: "",
        } )
    const [ confirmRequestData, setConfirmRequestData ] = useState( {} )
    const [bonusOptions, setBonusOptions] = useState([{innerText: "ปกติ", value:"all"}, {innerText: "รับโปร", value:"promotion"}, {innerText: "กิจกรรมเกม", value:"luckydraw"},{innerText: "ลิงค์รับทรัพย์", value:"affiliate"},{innerText: "โปรโมชั่นลูกค้าใหม่", value:"new_register_promotion"}])
    const typeAheadRef = useRef()
    const [customerStats, setCustomerStats] = useState( [] )
    const [luckyDrawList, setLuckyDrawList] = useState([])

    const { register:register2, handleSubmit:handleSubmit2, formState: { errors: errors2 }, getValues:getValues2, setValue:setValue2, reset:reset2 } = useForm( {
        defaultValues: {
            page:1,
            last_element_id:''
        },
        criteriaMode: "all"
    } );

    const { register:registerBonusStats, handleSubmit:handleSubmitBonusStats, formState: { errors: errorsBonusStats }, reset:resetBonusStats } = useForm( {
        defaultValues: {
            user_id:''
        },
        criteriaMode: "all"
    } );

    const [isLoadingBonusStats, setIsLoadingBonusStats] = useState(false)

    const handleCloseModal = () => {
        setRetryInfo( "" )
        setShowRetryDialog( false );
    }

    const renderSwitch = ( props ) => {
        // console.log("renderSwitch :", typeof(status))
        const { credit_status } = props
        //console.log("statusText :", credit_status)
        switch ( credit_status ) {
            case "success":
                return <td><h5><Badge variant="success">{credit_status}</Badge></h5></td>;
            case "failed":
                return <td><h5><Badge variant="danger">{credit_status}</Badge></h5></td>;
            case "deleted":
                return <td><h5><Badge variant="warning">{credit_status}</Badge></h5></td>;
            default:
                return <td><Spinner animation="grow" variant="info"/></td>;
        }
    }

    const handleRetry = async() => {
        //console.log("retry objectId: ", retryInfo.objectId);
        const work_order_request_retry = {
            id: retryInfo.objectId,
            updated_by: login_profile.name
        }

        try {
            const response = await api_workorder.WORK_ORDER_RETRY( work_order_request_retry )
            const { data } = response.data
            //console.log(`retry work order data: `, data);
            setRetryInfo( "" )
            setShowRetryDialog( false );
            searchRefundWithoutDate( retryInfo.objectId )
        } catch (e) {
            console.log(`retry work order failed`, e.message);
        }
    }


    const searchRefundWithoutDate = async( keyword ) => {

        if ( !keyword && keyword.length < 4 ) {
            return
        }

        //console.log("search keyword without date:", keyword)
        const response = await api_workorder.WORK_ORDER_SEARCH(
            {
                keyword: keyword,
                work_order_type: "bonus",
                work_order_subtype: "manual"
            }
        )
        //console.log("search customer by user id response: ", response);
        if ( response.status !== 200 ) {
            //console.log(`search customer by user id failed`, response.message);
            return
        }

        //console.log("search refund customer data : ", response.data)
        const { data } = response.data
        setDataTable( data.work_orders )
    }

    const [ keyword, setKeyword ] = useState( "" );
    const [ isButtonLoading, setIsButtonLoading ] = useState( false )
    const [bonusType, setBonusType] = useState("all")

    const searchRefund = async() => {
        setIsButtonLoading( true )

        // if ( !keyword && keyword.length === 0 ) {
        //     setDataTable( [] )
        //     return
        // }
        //
        // if ( !keyword && keyword.length < 4 ) {
        //     return
        // }

        // console.log( "search keyword :", keyword )
        // console.log( "search date :", startDate )
        // console.log( "search bonus type :", bonusType )
        //console.log("last_element_id : ", dictLastIndices[currentPage])
        const response = await api_workorder.WORK_ORDER_SEARCH(
            {
                keyword: keyword,
                work_order_type: "bonus",
                work_order_sub_type: bonusType,
                date: startDate,
                last_element_id: dictLastIndices[currentPage]
            }
        )
        // console.log("search customer by user id response: ", response);
        if ( response.status !== 200 ) {
            //console.log(`search customer by user id failed`, response.message);
            return
        }

        if ( response ) {
            setIsButtonLoading( false )
        }

        //console.log("search refund customer data : ", response.data)
        const { work_orders } = response.data.data
        if (work_orders) {
            const newIndex = {...dictLastIndices, [currentPage + 1]: work_orders[work_orders.length - 1].id}
            setDictLastIndices(newIndex)
        }
        setDataTable(work_orders)
    }

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const limitPage = 200;
    const [dictLastIndices, setDictLastIndices] = useState({1: ""});

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            handleSubmit2(onSubmit22)()
        }
    };

    const handleNextPage = () => {
        if (!dataTable) {
            return
        }

        if (dataTable.length > limitPage) {
            setCurrentPage(currentPage + 1)
            handleSubmit2(onSubmit22)()
        }
    };

    const retryRefund = ( data ) => {
        if ( data.credit_status !== "failed" ) {
            return
        }
        //console.log("retry refund: ", data)
        setRetryInfo( {
            objectId: data.id,
            user_id: data.user_id,
            first_name: data.first_name,
            last_name: data.last_name,
            amount: data.amount
        } )
        setShowRetryDialog( true )
    }

    const onSubmit = workOrderData => {

        const work_order_deposit_request = {
            ...workOrderData,
            work_order_type: "bonus",
            work_order_sub_type: "manual",
            channel: "manual",
            created_by: login_profile.name,
            updated_by: login_profile.name
        }

        setConfirmRequestData( work_order_deposit_request )
        setConfirmModalData( {
            work_order_label_type: "โบนัส",
            user_id: workOrderData.user_id,
            first_name: workOrderData.first_name,
            last_name: workOrderData.last_name,
            amount: workOrderData.amount,
        } )

        setConfirmModal( true )
    }

    const onSubmit22 = async (workOrderData2) => {

        // console.log("onSubmit2 form data: ", workOrderData2)
        // console.log("onSubmit2 current page : ", currentPage)
        const {search_customer, bonus_type, start_date} = workOrderData2
        setIsButtonLoading( true )
        // console.log("last_element_id : ", dictLastIndices[currentPage])
        const response = await api_workorder.WORK_ORDER_SEARCH(
            {
                keyword: search_customer,
                work_order_type: "bonus",
                work_order_sub_type: bonus_type,
                date: start_date,
                last_element_id: dictLastIndices[currentPage]
            }
        )
        // console.log("search customer by user id response: ", response);
        if ( response.status !== 200 ) {
            //console.log(`search customer by user id failed`, response.message);
            setIsButtonLoading( false )
            return
        }

        if ( response ) {
            setIsButtonLoading( false )
        }

        //console.log("search refund customer data : ", response.data)
        const { work_orders } = response.data.data
        if (work_orders) {
            const newIndex = {...dictLastIndices, [currentPage + 1]: work_orders[work_orders.length - 1].id}
            setDictLastIndices(newIndex)
        }
        setDataTable(work_orders)
        // console.log("dictLastIndices : ", dictLastIndices)
    }

    const handleSearchDataFromUserID = async( userIdForSearch ) => {
        const response = await api.SEARCH_CUSTOMER( { keyword: userIdForSearch } )
        // console.log("search customer by user id response: ", response);
        if ( response.status !== 200 ) {
            console.log( `search customer by user id failed`, response.message );
            return
        }

        const { data } = response.data
        if ( data === null ) {
            setCustomerSearchList( [] );
            return
        }
        //console.log("search customer by user id response data: ", data);

        let dataArr = []
        data.map( e => {
            // console.log("element data search: ", e)
            if ( e.agents.length === 2 ) {
                const agentsArr = e.agents
                agentsArr.map( ex => {
                    //console.log( "agents elements : ", ex )
                    let dTemp = { ...e, user_id: ex.username }
                    // dTemp.user_id = ex.username
                    dataArr.push( dTemp )
                } )

            } else {
                dataArr.push( e )
            }
        } )
        //console.log("element dataArr: ", dataArr)
        setCustomerSearchList( dataArr );
    }

    const handleSetDataFromUserID = ( value ) => {
        if ( value.length === 0 ) {
            return
        }
        //console.log("handleSetDataFromUserID : ", ...value)
        const result = value[ 0 ]
        //const result = value.filter(value => value.user_id === value).pop()
        const { user_id, first_name, last_name } = result
        //console.log("handleSetDataFromUserID : ", user_id, first_name, last_name, mobile_no, bank_accounts)
        setValue( "user_id", user_id )
        setValue( "first_name", first_name )
        setValue( "last_name", last_name )
    }

    const confirmSubmit = async( work_order_deposit_request ) => {
        const response = await api_workorder.WORK_ORDER_CREATE( work_order_deposit_request )

        if ( response.status !== 200 ) {
            console.log( `create work_order_deposit_failed`, response.message );
            return
        }

        dispatch( {
            type: "TOAST2",
            value: {
                show: true,
                type: 'success',
                title: `สร้างใบงาน Manual`,
                message: `สร้างใบงาน UserID ${work_order_deposit_request.user_id} สำเร็จ`,
            }
        } );

        setConfirmRequestData( {} )
        setConfirmModalData( {} )
        setConfirmModal( false )
        // typeAheadRef.current?.clear()
        reset( register )
    }

    const searchLuckyDrawList = async() => {

        try{
            const response = await luckydraw_api.SEARCH_LUCKYDRAW( { keyword: "", id: "" } );

            const { data } = response.data;
            console.log( "search data: ", data );

            if ( data !== null ) {
                let alignData = [];
                data.forEach( ( luckydraw => {
                        const align = {
                            "value": luckydraw.id,
                            "innerText": luckydraw.name,
                        };
                        alignData.push( align );
                    } )
                )
                setLuckyDrawList( alignData );
            }
        }catch (e){
            console.log(e)
        }


        // setSearchButton( false );
    };

    const onSubmitBonusStats = async (BonusStatsData) => {

        console.log("BonusStatsData data: ", BonusStatsData)
        // console.log("onSubmit2 current page : ", currentPage)

        try {
            setIsLoadingBonusStats(true)
            const response = await luckydraw_api.SEARCH_LUCKYDRAW_STATS( BonusStatsData )
            setCustomerStats( response.data.data?.customer_stats )
            // setSearchButton( false );
        } catch ( err ) {
            console.log( err );
            // setSearchButton( false );
        } finally {
            setIsLoadingBonusStats(false)
        }
    }

    const resetBonusStatsAndList = ()=>{
        resetBonusStats({})
        setCustomerStats([])
    }

    useEffect( () => {
        //console.log("register customer screen before dispatch");
        // if (process.env.REACT_APP_LUCKYDRAW_FLAG === "true") {
        //     setBonusOptions([{innerText: "ปกติ", value:"manual"}, {innerText: "กิจกรรมเกม", value:"luckydraw"}])
        // }

        try {
            dispatch( {
                type: "SOCKET_SEND",
                value: { method: "subscribe", topic: "dashboard_work_order_bonus_manual" }
            } );
        } catch ( error ) {
            console.log( "error :", error );
            dispatch( {
                type: "TOAST",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ระบบมีปัญหา`,
                    message: `ขออภัยระบบมีปัญหา กรุณาติดต่อ admin`,
                }
            } );
        }
    }, [] )

    useEffect( () => {

        //console.log( "dashboard_work_order_bonus_manual page: ", dashboard_work_order_bonus_manual );
        if ( dashboard_work_order_bonus_manual === undefined ) {
            return
        }
        setWorkOrderDetailsData(
            {
                ...workOrderDetailsData,
                total_work_order_all: dashboard_work_order_bonus_manual.total_work_order_all,
                total_work_order_today: dashboard_work_order_bonus_manual.total_work_order_today,
                total_amount_today: dashboard_work_order_bonus_manual.total_amount_today,
                work_order_type: "bonus",
                // work_order_sub_type: "lost"
            }
        )
    }, [ dashboard_work_order_bonus_manual ] )

    useEffect(() => {
        void searchLuckyDrawList()
    }, [])

    return (
        <>
            <ModalConfirmWorkOrder openModal={confirmModal} data={confirmModalData} onConfirm={confirmSubmit}
                                   onClose={() => setConfirmModal(false)} workOrderData={confirmRequestData}/>
            <div className="pt-4 pl-4 pr-4">
                <Modal show={showRetryDialog}>
                    <Modal.Header>
                        <Modal.Title>ใบงาน Bonus</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>User ID: {retryInfo.user_id}</p>
                        <p>ชื่อ: {retryInfo.user_id}</p>
                        <p>นามสกุล: {retryInfo.user_id}</p>
                        <p>จำนวนยอด: {retryInfo.amount}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={() => handleRetry()}>ยืนยัน</Button>
                        <Button variant="secondary" onClick={() => handleCloseModal(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Card className="card-primary">
                    <Card.Header>ค้นหาใบงาน Bonus</Card.Header>
                    <Card.Body>
                        <Form key={2} noValidate onSubmit={handleSubmit2(onSubmit22)}>
                            <Form.Row className="col-md-12">
                                <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                                    <Form.Label>เลือกวันที่</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Start-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            name="start_date"
                                            placeholder="2020-09-10"
                                            required
                                            // onChange={( e ) => setStartDate( moment( e.target.value ).format( "YYYY-MM-DD" ) )}
                                            defaultValue={startDate}
                                            ref={register2({required: true})}
                                            isInvalid={!!errors2.start_date}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาเลือกวันที่ต้องการค้นหา
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                </Form.Group>
                                <Form.Group as={Col} md="6" lg="6" className="col-md-12">
                                    <Form.Label>พิมพ์ค้นหาลูกค้า</Form.Label>
                                    <Form.Control as="input"
                                                  name="search_customer"
                                                  placeholder="eg. ufyu88xxxx/ahag2xxx, ชื่อ - นามสกุล, เบอร์โทรศัพท์, เลขที่บัญชี"
                                                  defaultValue={""}
                                                  ref={register2}
                                        // onChange={( e ) => setKeyword( e.target.value )}
                                    >
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3">
                                    <Form.Label>ประเภทของโบนัส</Form.Label>
                                    <InputGroup>
                                        <Form.Control as="select"
                                                      required
                                                      name="bonus_type"
                                            // onChange={(e) =>setBonusType(e.target.value)}
                                                      custom
                                                      defaultValue=""
                                                      ref={register2({required: true})}
                                                      isInvalid={!!errors2.bonus_type}
                                        >
                                            <option value="">Please Select</option>
                                            {
                                                bonusOptions.map(({innerText, value}, index) =>
                                                    <option
                                                        key={index}
                                                        value={value}
                                                    >
                                                        {innerText}
                                                    </option>
                                                )
                                            }

                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            กรุณาระบุ ประเภทโบนัส
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="4" lg="4">
                                    <Button variant='primary' type="submit"
                                        // onClick={searchRefund}
                                            disabled={isButtonLoading}>
                                        {
                                            isButtonLoading ?
                                                (
                                                    <>
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        /> กำลังค้นหา</>) :
                                                'ค้นหา'
                                        }
                                    </Button>
                                </Form.Group>
                                {
                                    (dataTable) ?
                                        <Pagination className={"ml-auto mt-3"}>
                                            <Pagination.Prev onClick={handlePrevPage}/>
                                            <Pagination.Item active={true}>
                                                {currentPage}
                                            </Pagination.Item>
                                            <Pagination.Next onClick={handleNextPage}/>
                                        </Pagination> : <></>
                                }

                            </Form.Row>
                        </Form>
                        <Table striped borderless hover responsive
                               className="table table-striped table-valign-middle">
                            <thead className="text-center">
                            <tr>
                                <th>#</th>
                                <th>วันที่/เวลา</th>
                                <th>UserID</th>
                                <th>ชื่อ-นามสกุล</th>
                                <th>จำนวนเงิน</th>
                                <th>สถานะ credit</th>
                                <th>Credit คงเหลือ</th>
                                <th>ผู้ทำรายการ</th>
                                <th>อัพเดทล่าสุด</th>
                                <th>หมายเหตุ</th>
                            </tr>
                            </thead>
                            {
                                !isButtonLoading &&
                                <tbody className="text-center">
                                {
                                    dataTable?.map((
                                        {
                                            id,
                                            created_at,
                                            user_id,
                                            first_name,
                                            last_name,
                                            to_bank_name,
                                            to_bank_account_no,
                                            amount,
                                            status,
                                            credit_status,
                                            after_balance,
                                            updated_at,
                                            updated_by,
                                            comment

                                        }, index) =>

                                        <tr key={id} onClick={() => retryRefund(dataTable[index])}
                                            style={{"cursor": "pointer"}}>

                                            <td>{index + 1}</td>
                                            <td>{moment(created_at).format('DD/MM/YYYY HH:mm')}</td>
                                            <td>{user_id || "-"}</td>
                                            <td>{first_name || "-"} {last_name || "-"}</td>
                                            <td>{amount}</td>
                                            {
                                                renderSwitch({credit_status})
                                            }
                                            {/* <td>{status}</td> */}
                                            <td>{after_balance}</td>
                                            <td>{updated_by}</td>
                                            <td>{moment(updated_at).format('DD/MM/YYYY HH:mm')}</td>
                                            <td>{comment}</td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            }
                        </Table>
                        {
                            (dataTable) ?
                                <Row>
                                    <Pagination className={"ml-auto mr-4 mt-3"}>
                                        <Pagination.Prev onClick={handlePrevPage}/>
                                        <Pagination.Item active={true}>
                                            {currentPage}
                                        </Pagination.Item>
                                        <Pagination.Next onClick={handleNextPage}/>
                                    </Pagination>
                                </Row> : <></>
                        }
                    </Card.Body>
                </Card>
            </div>
            <div className="pt-4 pr-4 pl-4">
                <Card className="card-danger">
                    <Card.Header>สถิติการรับโบนัสของลูกค้ารายคน</Card.Header>
                    <Card.Body>
                        <Form key={4} noValidate onSubmit={handleSubmitBonusStats(onSubmitBonusStats)}>
                            <Form.Row>
                                <Form.Group as={Col} md="4" lg="4" className="col-md-12">
                                    <Form.Label>กรุณาเลือกชนิดโบนัสที่ต้องการค้นหา</Form.Label>
                                    <Form.Control as="select"
                                                  required
                                                  name="id"
                                                  defaultValue=""
                                                  isInvalid={!!errorsBonusStats.id}
                                                  custom
                                                  ref={registerBonusStats( { required: true } )}
                                    >
                                        <option value="">Please Select</option>
                                        {
                                            luckyDrawList.map( ( { innerText, value }, index ) =>
                                                <option
                                                    key={index}
                                                    value={value}
                                                >
                                                    {innerText}
                                                </option>
                                            )
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาเลือกรูปแบบการแจก Lucky Draw
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="8" lg="8" className="col-md-12">
                                    <Form.Label>พิมพ์ค้นหาสถิติโบนัสของลูกค้า (User ID)</Form.Label>
                                    <Form.Control as="input"
                                                  name="user_id"
                                                  placeholder="eg. ufyu88xxxx/ahag2xxx"
                                                  defaultValue={""}
                                                  ref={registerBonusStats({required:true})}
                                                  isInvalid={!!errorsBonusStats.user_id}
                                    >
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุ User ID
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="4" lg="4">
                                    <Button variant='primary' type="submit"
                                        // onClick={searchRefund}
                                            disabled={isLoadingBonusStats}>
                                        {
                                            isLoadingBonusStats ?
                                                (
                                                    <>
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        /> กำลังค้นหา</>) :
                                                'ค้นหา'
                                        }
                                    </Button>
                                    {' '}
                                    <Button variant='danger' type="button" onClick={()=>resetBonusStatsAndList()}>
                                                ล้างค่า
                                    </Button>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <Table responsive hover size="md" className="text-center">
                            <thead className="text-center">
                            <tr>
                                <th>#</th>
                                <th>period_type</th>
                                <th>period_type_key</th>
                                <th>วันที่ update</th>
                                <th>user_id</th>
                                <th>จำนวนลูกค้าที่รับไปแล้ว (คน)</th>
                                <th>จำนวนเงินที่แจกไปแล้ว (บาท)</th>
                                <th>amount (บาท)</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {
                                customerStats ?
                                    customerStats.map( ( {
                                                             id,
                                                             count,
                                                             total_amount,
                                                             amount,
                                                             user_id,
                                                             updated_at,
                                                             period_type,
                                                             period_type_key,
                                                         }, index ) =>
                                        <tr key={index + 1} className="" style={{ "cursor": "pointer" }}>
                                            <td><h6>{index + 1}</h6></td>
                                            <td><h6>{period_type}</h6></td>
                                            <td><h6>{period_type_key}</h6></td>
                                            <td><h6>{moment( updated_at ).format( 'DD/MM/YYYY HH:mm:ss' )}</h6></td>
                                            <td><h6>{user_id}</h6></td>
                                            <td><h6>{count}</h6></td>
                                            <td><h6>{total_amount}</h6></td>
                                            <td><h6>{amount}</h6></td>
                                        </tr>
                                    )
                                    :
                                    (<tr>
                                        <td colSpan={8}>
                                            <p>ไม่มีข้อมูล</p>
                                        </td>
                                    </tr>)

                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
                        <div className="pt-4 pr-4 pl-4">
                            <Card className="card-secondary">
                                <Card.Header>เพิ่มใบงานโบนัส</Card.Header>
                                <Card.Body>
                                    <Form key={1} noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4" lg="4">
                                                <Form.Label>ค้นหาลูกค้า</Form.Label>
                                                <AsyncTypeahead
                                                    // size="md"
                                                    delay={1500}
                                                    id="search_customer"

                                                    isLoading={isLoading}
                                                    labelKey={(option) => `${option.user_id} ${option.first_name} ${option.last_name}`}
                                                    minLength={4}
                                                    onSearch={handleSearchDataFromUserID}
                                                    options={customerSearchList}
                                                    placeholder="user_id/ชื่อ/นามสกุล/เบอร์โทรศัพท์/เลขที่บัญชี"
                                                    searchText="กำลังค้นหาลูกค้า"
                                                    onChange={handleSetDataFromUserID}
                                                    renderMenuItemChildren={(option) => (
                                                        <div className={"flex hover:bg-blue-200"}>
                                                            <div style={{"width": "50%"}}>
                                                                <p><b className={"text-primary"}>{option.user_id}</b>
                                                                </p>
                                                                <p><b>{option.first_name} - {option.last_name}</b></p>
                                                            </div>
                                                            <div className={"flex-row"}>
                                                                {
                                                                    option.bank_accounts.map((bank) => {
                                                                        return (
                                                                            <p>
                                                                                <LogoWithAccountNoSimple
                                                                                    bankShortName={bank.bank_short_name}
                                                                                    bankFullName={bank.bank_account_name}
                                                                                    bankName={bank.bank_account_name}
                                                                                    accountNo={bank.bank_account_no}
                                                                                />
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="2" lg="2" controlId="validation01">
                                                <Form.Label>UserID</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="user_id"
                                                    placeholder="ufyuxxxxx/ahagxxxx"
                                                    isInvalid={!!errors.first_name}
                                                    ref={register({required: true})}
                                                    readOnly={true}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    กรุณาระบุ ชื่อ
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" lg="2" controlId="validation02">
                                                <Form.Label>ชื่อ</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="first_name"
                                                    placeholder="ชื่อ"
                                                    isInvalid={!!errors.first_name}
                                                    ref={register({required: true})}
                                                    readOnly={true}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    กรุณาระบุ ชื่อ
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" lg="2" controlId="validation03">
                                                <Form.Label>นามสกุล</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="last_name"
                                                    placeholder="นามสกุล"
                                                    isInvalid={!!errors.last_name}
                                                    ref={register({required: true})}
                                                    readOnly={true}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    กรุณาระบุนามสกุล
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="2" lg="2" controlId="validation08">
                                                <Form.Label>จำนวนเงิน</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    name="amount"
                                                    step={1}
                                                    placeholder="100/200.50"
                                                    isInvalid={!!errors.amount}
                                                    ref={register({required: true})}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    กรุณาระบุ จำนวนเงิน
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" lg="6" controlId="validation09">
                                                <Form.Label>หมายเหตุ</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="comment"
                                                    placeholder="อ่าน statement ไม่เจอ/ธนาคารมีปัญหา"
                                                    isInvalid={!!errors.comment}
                                                    ref={register}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    กรุณาระบุ จำนวนเงิน
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Button variant="success" type="submit">เพิ่มใบงานฝากโบนัส</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="pt-4 pr-4 pl-4">
                            <NewCustomerBonus/>
                        </div>
                        <div>
                            <WorkOrderDetails data={workOrderDetailsData}/>
                        </div>
                    </>
                    )
                    }
