import React, {useEffect, useMemo, useRef, useState} from "react";
import { Bar } from "react-chartjs-2";
import {
    Button,
    Card,
    Col,
    Form,
    FormControl,
    InputGroup,
    Row,
    Spinner,
    Table,
} from "react-bootstrap";
import moment from "moment";
import api_bank from "../../api/managebank";
import api from "../../api/dashboard";
import api_agent from "../../api/agents";
import NumberFormat from "react-number-format";
import { useGlobalStore } from "../../store/globalContext";
import { number_format } from "../utils/Number";
import { InfoBoxBGStatsCard } from "./StatCard";
import ChannelChart from "./Channel";
import DepositWithdrawTableByChannels from "./DepositWithdrawTableByChannels";

export default function DashBoard() {

    const { login_profile } = useGlobalStore()[0];
    const [activeUser, setActiveUser] = useState(0);
    const [newUfaUser, setNewUfaUser] = useState(0);
    const [newGClubUser, setNewGClubUser] = useState(0);
    const [summaryAccount, setSummaryAccount] = useState({
        deposit: "0.0",
        withdraw: "0.0",
        sweepWithdraw: "0.0",
        sweepHolding: "0.0",
    });
    const [summaryLost, setSummaryLost] = useState({
        total: "0.0",
        redeem: "0.0",
        failed: "0.0",
        pending: "0.0",
        lost: "0.0",
        bonus: "0.0",
    });

    const [hoverData, setHoverData] = useState({
        ufaDeposit: [],
        gclubDeposit: [],
        trueWalletDeposit: [],
        ufaWithdraw: [],
        gclubWithdraw: [],
        depositManual: [],
    });

    const [activeHover, setActiveHover] = useState({
        ufaActive: [],
        gclubActive: [],
    });

    const [workOrderHover, setWorkOrderHover] = useState({
        ufaDeposit: [],
        ufaWithdraw: [],
        gclubDeposit: [],
        gclubWithdraw: [],
    });

    const ed = new Date();
    const std = new Date().setDate(ed.getDate() - 15);
    const [startDate, setStartDate] = useState(moment(std).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(ed).format("YYYY-MM-DD"));
    const [chartData, setChartData] = useState({ datasets: []});
    const [lostChartData, setLostChartData] = useState({});
    const [sweepChartData, setSweepChartData] = useState({});
    const [activeCustomerData, setActiveCustomerData] = useState({});
    const [newUserData, setNewUserData] = useState({});
    const [workOrderData, setWorkOrderData] = useState({});
    const [typeTop10Account, setTypeTop10Account] = useState("");
    const [bankSelectTop10Account, setBankSelectTop10Account] = useState([]);
    const [bankTop10Account, setBankTop10Account] = useState("");
    const [dataTop10, setDataTop10] = useState([]);
    const [styleDate, setStyleDate] = useState({});
    const [dashboardWarning, setDashboardWarning] = useState(0);
    const [dashboardWarningDetails, setDashboardWarningDetails] = useState([]);
    const dateRef = useRef();

    const [userRegisNotTransfer, setUserRegisNotTransfer] = useState([]);

    const [agentsSummary, setAgentsSummary] = useState([]);

    const [agentsWorkOrders, setAgentsWorkOrders] = useState([]);
    const [agentsWorkOrdersDate, setAgentsWorkOrdersDate] = useState(
        moment(ed).format("YYYY-MM-DDT12:00:00.000+07:00")
    );
    const [agentsWorkOrdersTime, setAgentsWorkOrdersTime] = useState("12:00");
    const [agentsWorkOrdersDisplayText, setAgentsWorkOrdersDisplayText] =
        useState("");

    const [loading, setLoading] = useState(false);
    const [loadingAgentsWorkOrder, setLoadingAgentsWorkOrder] = useState(false);

    const [activeAgent, setActiveAgent] = useState([]);

    useEffect(() => {
        setLoading(true);
        void getActiveAgent()
        Promise.all([
        void getDepositWithdrawSummaryGraphAPI(),
        void getActiveCustomersSummaryGraphAPI(),
        void getSummaryWorkOrder(),
        void getRefundMonthly(),
        void getCustomerStat(),
        void getCustomerStat({
            bank_type: typeTop10Account,
            bank_account: bankTop10Account,
        }),
        setStickyDate(),
        void getDashboardWarning(),
        void getRegisterNotTransferUsers(),
        void getAgentsSummary(),
        // void getNewUserSummary()
        ]).then(() => setLoading(false));
    }, []);

    useEffect(()=>{
        void getNewUserSummary()
    },[activeAgent])

    const setStickyDate = () => {
        const sticky = dateRef.current.offsetTop;
        // console.log(dateRef)
        // console.log(sticky)
        const scrollCallBack = window.addEventListener("scroll", () => {

            if (window.pageYOffset > sticky) {
                setStyleDate({
                    position: "fixed",
                    top: 0,
                    zIndex: 10,
                });
            } else {
                setStyleDate({});
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    };

    const submit = () => {
        // console.log("start-date: ", startDate);
        // console.log("end-date: ", endDate);
        setLoading(true);
        Promise.all([
            getActiveAgent(),
            getDepositWithdrawSummaryGraphAPI(),
            getDepositWithdrawSummaryGraphAPI(),
            getDepositWithdrawSummaryGraphAPI(),
            getActiveCustomersSummaryGraphAPI(),
            getSummaryWorkOrder(),
            // getNewUserSummary(),
            getRefundMonthly(),
            getCustomerStat({
                bank_type: typeTop10Account,
                bank_account: bankTop10Account,
            }),
            getDashboardWarning(),
            getRegisterNotTransferUsers(),
            getAgentsSummary(),
        ]).then(() => setLoading(false));
    };

    const activeAgentMemo = useMemo(()=>{
        return activeAgent
    },[activeAgent])

    const getAgentIndex = (agent)=>{
        // console.log("agent: ", agent)
        // console.log("activeAgentMemo.findIndex(e=> e === agent) :", activeAgent.findIndex(e=> e === agent))
        const value = activeAgentMemo.findIndex(e=> e === agent)
        return value
    }

    const getDepositWithdrawSummaryGraphAPI = async () => {
        const response = await api.DASHBOARD_DEPOSIT_WITHDRAW_STATS({
            start: startDate,
            end: endDate,
        });
        if (response.status !== 200) {
            console.log(
                `search deposit withdraw summary graph api`,
                response.message
            );
            return;
        }

        const { data } = response.data;
        // console.log( `deposit withdraw summary graph api :`, data );

        // bar graph per date
        let labels = [];
        let depositData = [];
        let withdrawData = [];
        let depositBGColor = [];
        let withdrawBGColor = [];

        // summary total per window date
        let sumDeposit = 0;
        let sumWithdraw = 0;
        let sumSweepWithdraw = 0;
        let sumSweepHolding = 0;

        // bonus per date
        let bonusLostData = [];
        let bonusWithoutLostData = [];
        let bonusLostBGColor = [];
        let bonusWithoutLostBGColor = [];

        // sweep per date
        let sweepHoldingData = [];
        let sweepHoldingBGColor = [];

        // hover date
        let ufaDepositData = [];
        let ufaWithdrawData = [];
        let gclubDepositData = [];
        let gclubWithdrawData = [];
        let trueWalletDepositData = [];
        let depositManual = [];

        let depositTooltips = []
        let withdrawTooltips = []

        data.map(
            (
                {
                    date,
                    deposit,
                    withdraw,
                    sweep_withdraw,
                    sweep_holding,
                    bonus_lost,
                    bonus_without_lost,
                    deposit_ufa,
                    deposit_gclub,
                    withdraw_ufa,
                    withdraw_gclub,
                    deposit_true_wallet,
                    deposit_manual,
                },
                index
            ) => {
                labels.push(moment(date).format("DD/MM/YYYY"));
                depositData.push(deposit);
                withdrawData.push(withdraw);

                ufaDepositData.push(deposit_ufa);
                ufaWithdrawData.push(withdraw_ufa);
                gclubDepositData.push(deposit_gclub);
                gclubWithdrawData.push(withdraw_gclub);
                trueWalletDepositData.push(deposit_true_wallet);
                depositManual.push(deposit_manual);

                bonusLostData.push(bonus_lost);
                bonusWithoutLostData.push(bonus_without_lost);
                sweepHoldingData.push(sweep_holding);

                depositBGColor.push("rgb(0,165,227)");
                withdrawBGColor.push("rgb(255,87,104)");

                bonusLostBGColor.push("rgb(0,227,11)");
                bonusWithoutLostBGColor.push("rgb(255,87,104)");
                sweepHoldingBGColor.push("rgb(93,255,87)");

                sumDeposit += deposit;
                sumWithdraw += withdraw;
                sumSweepWithdraw += sweep_withdraw;
                sumSweepHolding += sweep_holding;
            }
        );

        sumDeposit = number_format(sumDeposit, 2);
        sumWithdraw = number_format(sumWithdraw, 2);
        sumSweepWithdraw = number_format(sumSweepWithdraw, 2);
        sumSweepHolding = number_format(sumSweepHolding, 2);

        setHoverData({
            ufaDeposit: ufaDepositData,
            gclubDeposit: gclubDepositData,
            trueWalletDeposit: trueWalletDepositData,
            ufaWithdraw: ufaWithdrawData,
            gclubWithdraw: gclubWithdrawData,
            depositManual: depositManual,
        });

        // console.log("depositData: ",depositData)
        // console.log("withdrawData: ", withdrawData)
        // console.log("ufaDepositData: ", ufaDepositData)
        // console.log("hoverData: ", hoverData)
        const datasets = {
            labels: labels,
            datasets: [
                {
                    label: "ยอดเงินฝาก",
                    data: depositData.map(x => x),
                    backgroundColor: depositBGColor,
                    // borderWidth: 2,
                    // stack: 1,
                    // minBarLength: 2,
                    borderColor: "black",
                },
                {
                    label: "ยอดเงินถอน",
                    data: withdrawData.map(x => x),
                    backgroundColor: withdrawBGColor,
                    // borderWidth: 2,
                    // stack: 2,
                    // minBarLength: 2,
                    borderColor: "black"
                },
            ],

        };
        // console.log("chart summary deposit withdraw label: ", datasets)
        setChartData(datasets);

        const lostDatasets = {
            labels: labels,
            datasets: [
                {
                    label: "ยอดเสีย",
                    data: bonusLostData,
                    backgroundColor: bonusLostBGColor,
                    borderWidth: 2,
                    stack: 1,
                    // barThickness: 1,
                    // maxBarThickness: 5,
                    minBarLength: 2,
                    borderColor: "black",
                },
                {
                    label: "โบนัส",
                    data: bonusWithoutLostData,
                    backgroundColor: bonusWithoutLostBGColor,
                    borderWidth: 2,
                    stack: 2,
                    // barThickness: 1,
                    // maxBarThickness: 5,
                    minBarLength: 2,
                    borderColor: "black",
                },
            ],
        };
        setLostChartData(lostDatasets);

        const sweepDatasets = {
            labels: labels,
            datasets: [
                {
                    label: "ถอนไปพัก",
                    data: sweepHoldingData,
                    backgroundColor: sweepHoldingBGColor,
                    borderWidth: 2,
                    stack: 1,
                    // barThickness: 1,
                    // maxBarThickness: 5,
                    minBarLength: 2,
                    borderColor: "black",
                },
            ],
        };
        setSweepChartData(sweepDatasets);

        setSummaryAccount({
            deposit: sumDeposit,
            withdraw: sumWithdraw,
            sweepWithdraw: sumSweepWithdraw,
            sweepHolding: sumSweepHolding,
        });
        // setSuccessFetchAPI( successFetchAPI + 1 )
    };

    const getActiveCustomersSummaryGraphAPI = async () => {
        try {
            const response = await api.DASHBOARD_ACTIVE_USERS({
                start: startDate,
                end: endDate,
            });
            if (response.status !== 200) {
                console.log(
                    `search active customer summary graph api`,
                    response.message
                );
                return;
            }

            const data = response.data.data;
            // console.log(`active customer summary graph api :`, response);

            if (!data) {
                return;
            }

            setActiveUser(data[data.length - 1].active_user);

            let labels = [];
            let activeUserData = [];
            let activeUserBGColor = [];

            let ufaActiveUser = [];
            let gclubActiveUser = [];

            //console.log("DATA : ", data)
            data.map(
                ({ date, active_user, active_user_ufa, active_user_gclub }, index) => {
                    labels.push(moment(date).format("DD/MM/YYYY"));
                    activeUserData.push(active_user);
                    activeUserBGColor.push("rgb(141,215,191)");

                    ufaActiveUser.push(active_user_ufa);
                    gclubActiveUser.push(active_user_gclub);
                }
            );

            const datasets = {
                labels: labels,
                datasets: [
                    {
                        label: "Active Customer",
                        data: activeUserData,
                        backgroundColor: activeUserBGColor,
                        borderWidth: 2,
                        stack: 1,
                        // barThickness: 1,
                        // maxBarThickness: 5,
                        minBarLength: 2,
                        borderColor: "black",
                    },
                ],
            };
            //console.log( "chart active customer label: ", datasets )
            setActiveCustomerData(datasets);

            setActiveHover({
                ufaActive: ufaActiveUser,
                gclubActive: gclubActiveUser,
            });

            // setSuccessFetchAPI( successFetchAPI + 1 )
        } catch (e) {
            console.log("error get active customer dashboard", e);
        }
    };

    const getNewUserSummary = async () => {
        try {
            const response = await api.DASHBOARD_NEW_USERS({
                start: startDate,
                end: endDate,
            });
            if (response.status !== 200) {
                console.log(`search new user summary graph api`, response.message);
                return;
            }

            const data = response.data.data;
            // console.log(`new user summary graph api :`, response);

            if (!data) {
                return;
            }

            setNewUfaUser(data[data.length - 1].new_user_ufa);
            setNewGClubUser(data[data.length - 1].new_user_gclub);

            let labels = [];
            let newUserUFAData = [];
            let newUserGClubData = [];
            let newUserUFABGColor = [];
            let newUserGClubBGColor = [];
            //console.log("DATA : ", data)
            data.map(({ date, new_user_ufa, new_user_gclub }, index) => {
                labels.push(moment(date).format("DD/MM/YYYY"));
                newUserUFAData.push(new_user_ufa);
                newUserGClubData.push(new_user_gclub);
                newUserUFABGColor.push("rgb(255,216,114)");
                newUserGClubBGColor.push("rgb(108,136,196)");
            });

            const newUserDataSet = []
            if(getAgentIndex("ufa") !== -1){
                newUserDataSet.push({
                    label: "New User UFA",
                    data: newUserUFAData,
                    backgroundColor: newUserUFABGColor,
                    borderWidth: 2,
                    stack: 1,
                    // barThickness: 1,
                    // maxBarThickness: 5,
                    minBarLength: 2,
                    borderColor: "black",
                })
            }

            if(getAgentIndex("gclub") !== -1){
                newUserDataSet.push({
                    label: "New User Gclub",
                    data: newUserGClubData,
                    backgroundColor: newUserGClubBGColor,
                    borderWidth: 2,
                    stack: 2,
                    // barThickness: 1,
                    // maxBarThickness: 5,
                    minBarLength: 2,
                    borderColor: "black",
                })
            }

            const datasets = {
                labels: labels,
                datasets: newUserDataSet,
            };
            // console.log( "chart new user label: ", datasets )

            setNewUserData(datasets);
            // setSuccessFetchAPI( successFetchAPI + 1 )
        } catch (e) {
            console.log("error get new user dashboard", e);
        }
    };

    const getSummaryWorkOrder = async () => {
        const response = await api.DASHBOARD_WORK_ORDER({
            start: startDate,
            end: endDate,
        });
        if (response.status !== 200) {
            console.log(
                `search summary dashboard work order graph api`,
                response.message
            );
            return;
        }

        const { data } = response.data;
        // console.log(`summary dashboard work order graph api :`, data);

        let labels = [];
        let depositData = [];
        let withdrawData = [];
        let depositBGColor = [];
        let withdrawBGColor = [];

        let ufaDeposit = [];
        let ufaWithdraw = [];
        let gclubDeposit = [];
        let gclubWithdraw = [];

        data.map(
            (
                {
                    date,
                    deposit,
                    withdraw,
                    deposit_ufa,
                    deposit_ufa_auto,
                    deposit_ufa_manual,
                    deposit_ufa_bonus,
                    deposit_ufa_lost,
                    deposit_gclub,
                    deposit_gclub_auto,
                    deposit_gclub_manual,
                    deposit_gclub_bonus,
                    deposit_gclub_lost,
                    // deposit_ufa_vizpay,
                    // deposit_gclub_vizpay,
                    // deposit_ufa_corepay,
                    // deposit_gclub_corepay,
                    // deposit_ufa_hengpay,
                    // deposit_gclub_hengpay,
                    // deposit_ufa_perfectpays,
                    // deposit_gclub_perfectpays,
                    deposit_ufa_e2p,
                    deposit_gclub_e2p,
                    deposit_ufa_hengpay68,
                    deposit_gclub_hengpay68,
                    deposit_ufa_payonex,
                    deposit_gclub_payonex,
                    deposit_ufa_jompay,
                    deposit_gclub_jompay,
                    withdraw_ufa,
                    withdraw_gclub,
                    withdraw_ufa_vizpay,
                    withdraw_gclub_vizpay,
                    withdraw_ufa_perfectpays,
                    withdraw_gclub_perfectpays,
                },
                index
            ) => {
                labels.push(moment(date).format("DD/MM/YYYY"));
                depositData.push(deposit);
                withdrawData.push(withdraw);
                depositBGColor.push("rgb(0,205,172)");
                withdrawBGColor.push("rgb(255,111,104)");


                ufaDeposit.push({
                    deposit_ufa: deposit_ufa,
                    deposit_ufa_auto: deposit_ufa_auto,
                    deposit_ufa_manual: deposit_ufa_manual,
                    deposit_ufa_bonus: deposit_ufa_bonus,
                    deposit_ufa_lost: deposit_ufa_lost,
                    // deposit_ufa_vizpay: deposit_ufa_vizpay,
                    // deposit_ufa_corepay: deposit_ufa_corepay,
                    // deposit_ufa_hengpay: deposit_ufa_hengpay,
                    // deposit_ufa_perfectpays: deposit_ufa_perfectpays,
                    deposit_ufa_e2p: deposit_ufa_e2p,
                    deposit_ufa_hengpay68: deposit_ufa_hengpay68,
                    deposit_ufa_payonex: deposit_ufa_payonex,
                    deposit_ufa_jompay: deposit_ufa_jompay,
                });

                ufaWithdraw.push(withdraw_ufa);

                gclubDeposit.push({
                    deposit_gclub: deposit_gclub,
                    deposit_gclub_auto: deposit_gclub_auto,
                    deposit_gclub_manual: deposit_gclub_manual,
                    deposit_gclub_bonus: deposit_gclub_bonus,
                    deposit_gclub_lost: deposit_gclub_lost,
                    deposit_gclub_e2p: deposit_gclub_e2p,
                    deposit_gclub_hengpay68: deposit_ufa_hengpay68,
                    deposit_gclub_payonex: deposit_ufa_payonex,
                    deposit_gclub_jompay: deposit_ufa_jompay,
                });
                gclubWithdraw.push(withdraw_gclub);
            }
        );

        // console.log("object keys ", data.map(e=>Object.keys(e)))

        const datasets = {
            labels: labels,
            datasets: [
                {
                    label: "จำนวนใบงานฝาก",
                    data: depositData,
                    backgroundColor: depositBGColor,
                    borderWidth: 2,
                    stack: 1,
                    borderColor: "black",
                },
                {
                    label: "จำนวนใบงานถอน",
                    data: withdrawData,
                    backgroundColor: withdrawBGColor,
                    borderWidth: 2,
                    stack: 2,
                    borderColor: "black",
                },
            ],
            keys: data,
        };
        //console.log("chart work order label: ", datasets)
        setWorkOrderData(datasets);

        setWorkOrderHover({
            ufaDeposit: ufaDeposit,
            ufaWithdraw: ufaWithdraw,
            gclubDeposit: gclubDeposit,
            gclubWithdraw: gclubWithdraw,
        });

        // setSuccessFetchAPI( successFetchAPI + 1 )
    };

    const getRefundMonthly = async () => {
        try {
            const request = {
                start: startDate,
                end: endDate,
            };
            const response = await api.DASHBOARD_BONUS_LOST_STATS(request);
            const { data } = response.data;

            setSummaryLost({
                total: number_format(data.total, 2),
                redeem: number_format(data.redeem, 2),
                failed: number_format(data.failed, 2),
                pending: number_format(data.pending, 2),

                lost: number_format(data.lost, 2),
                bonus: number_format(data.bonus, 2),
            });

            // setSuccessFetchAPI( successFetchAPI + 1 )
        } catch (error) {
            console.log(`dashboard bonus lost stat error: `, error.message);
        }
    };

    const getBankList = async (value) => {
        try {
            const responseDeposit = await api_bank.BANK_SEARCH_TYPE({
                keyword: value,
            });

            const { data } = responseDeposit.data;
            // console.log(data)
            if (data !== undefined && data !== null) {
                setBankSelectTop10Account(data);
            }
        } catch (error) {
            console.log("get bank list type error: ", error.message);
        }
    };

    const handleTypeTop10Account = async (value) => {
        setTypeTop10Account(value);
        setBankSelectTop10Account([]);
        void getBankList(value);

        const request = {
            bank_type: value,
        };
        void getCustomerStat(request);
    };

    const handleTop10Account = async (value) => {
        const account = bankSelectTop10Account.find((e) => e.id === value);
        setBankTop10Account(account.bank_account_no);
        const request = {
            bank_account_no: account.bank_account_no,
            bank_type: account.bank_type,
        };
        getCustomerStat(request);
    };

    const getCustomerStat = async (value) => {
        try {
            const request = {
                ...value,
                start: startDate,
                end: endDate,
            };
            const response = await api.DASHBOARD_CUSTOMER_STATS(request);

            const { data } = response.data;
            // console.log(data)
            setDataTop10(data);
            // setSuccessFetchAPI( successFetchAPI + 1 )
        } catch (error) {
            console.log("get top 10 spending error: ", error.message);
        }
    };

    const getDashboardWarning = async () => {
        await api
            .DASHBOARD_WARNING()
            .then((response) => {
                //console.log("dashboard warning response: ", response.data.data)
                const { messages } = response.data.data;
                setDashboardWarning(messages.length);
                //console.log("dashboardWarning :", dashboardWarning);
                // messages.map( ( msg ) => {
                //     console.log( "msg :", msg )
                // } )
                setDashboardWarningDetails(messages);
                // setSuccessFetchAPI( successFetchAPI + 1 )
            })
            .catch((error) => {
                console.log("get dashboard warning error: ", error.message);
            });
    };

    const getRegisterNotTransferUsers = async () => {
        try {
            const request = {
                start: startDate,
            };
            const response = await api.DASHBOARD_CUSTOMER_NO_DEPOSIT(request);

            const { data } = response.data;
            // console.log(data)
            setUserRegisNotTransfer(data.customers);
            // setSuccessFetchAPI( successFetchAPI + 1 )
        } catch (error) {
            console.log("get user register not transfer error: ", error.message);
        }
    };

    const getAgentsSummary = async () => {
        try {
            const request = {
                start: startDate,
                end: endDate,
            };
            const response = await api.DASHBOARD_AGENTS_SUMMARY(request);

            const { data } = response.data;
            setAgentsSummary(data);
            // setSuccessFetchAPI( successFetchAPI + 1 )
        } catch (error) {
            console.log("get agents summary error: ", error.message);
        }
    };

    const getAgentsWorkOrders = async () => {
        setLoadingAgentsWorkOrder(true);
        let request = {};
        if (agentsWorkOrdersTime === "12:00") {
            request = {
                start: moment(agentsWorkOrdersDate).format(
                    "YYYY-MM-DDT12:00:00.000+07:00"
                ),
                end: moment(moment(agentsWorkOrdersDate).add(1, "days")).format(
                    "YYYY-MM-DDT12:00:00.000+07:00"
                ),
            };
        } else {
            request = {
                start: moment(agentsWorkOrdersDate).format(
                    "YYYY-MM-DDT00:00:00.000+07:00"
                ),
                end: moment(moment(agentsWorkOrdersDate).add(1, "days")).format(
                    "YYYY-MM-DDT00:00:00.000+07:00"
                ),
            };
        }
        setAgentsWorkOrdersDisplayText(
            `ข้อมูลระหว่าง ${moment(request.start).format(
                "วันที่ YYYY-MM-DD เวลา HH:mm:ss"
            )} ถึง ${moment(request.end).format("วันที่ YYYY-MM-DD เวลา HH:mm:ss")}`
        );
        try {
            const response = await api.DASHBOARD_AGENTS_WORKORDERS(request);

            const { data } = response.data;
            setAgentsWorkOrders(data);
            setLoadingAgentsWorkOrder(false);
        } catch (error) {
            console.log("get agents work orders error: ", error.message);
            setLoadingAgentsWorkOrder(false);
        }
    };

    const getActiveAgent = async()=>{
        try {
            const response = await api_agent.GET_ACTIVE_AGENT();

            const { data } = response.data;
            // console.log("active agent data: ", data)
            setActiveAgent(data)
        } catch (error) {
            console.log("get active agents error: ", error.message);
        }
    }

    return (
        <div className="content w-full">
            <div ref={dateRef}>
                <Card className="valign-middle w-full"
                      style={styleDate}
                >
                    <Card.Header>
                        <Card.Title>
                            <h4>กรุณาระบุวันที่ </h4>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                <Col sm={12} md={5} lg={5} xl={5}>
                                    <Form.Label htmlFor="inlineFormInputGroupStartDate" srOnly>
                                        2020-09-21
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Start-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            id="inlineFormInputGroupStartDate"
                                            placeholder="2020-09-10"
                                            onChange={(e) =>
                                                setStartDate(
                                                    moment(e.target.value).format("YYYY-MM-DD")
                                                )
                                            }
                                            defaultValue={startDate}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm={12} md={5} lg={5} xl={5}>
                                    <Form.Label htmlFor="inlineFormInputGroupEndDate" srOnly>
                                        2020-09-21
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>End-Date</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="date"
                                            id="inlineFormInputGroupEndDate"
                                            placeholder="2020-09-21"
                                            onChange={(e) =>
                                                setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                                            }
                                            defaultValue={endDate}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col sm={12} md={2} lg={2} xl={2}>
                                    {loading ? (
                                        <Button type="button" disabled={true}>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{" "}
                                            กำลังค้นหา
                                        </Button>
                                    ) : (
                                        // <Button type="button" onClick={submit}>ค้นหาข้อมูล</Button>
                                        <Button type="button" onClick={submit}>
                                            <i className={"fas fa-search"} />
                                        </Button>
                                    )}
                                </Col>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
            </div>

            {!loading && (
                <>
                    {login_profile.role !== "agent" &&
                        login_profile.role !== "user_new" &&
                        login_profile.role !== "user_no_withdraw" &&
                        login_profile.role !== "normal" &&
                        login_profile.role !== "user_no_swap" &&
                        dashboardWarning > 0 && (
                            <>
                                <Card className="card-info">
                                    <Card.Header>
                                        <h5>จำนวนยูสคงเหลือ (ตาม agent)</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row lg={6}>
                                            {dashboardWarningDetails.map((msg, index) => {
                                                return (
                                                    <Col lg={6} key={index}>
                                                        <InfoBoxBGStatsCard
                                                            icon={"fas fa-people-arrows"}
                                                            value={msg.label}
                                                            label={msg.value}
                                                            type={"bg-danger"}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </>
                        )}

                    <Row sm={12} md={12} lg={12}>
                        <Col sm={12} md={12} lg={12}>
                            <Card className="card-success">
                                <Card.Header>
                                    <h5>ข้อมูลลูกค้าใหม่</h5>
                                </Card.Header>
                                <Card.Body>
                                    {/*<Row>*/}
                                    <Row>
                                        <Col >
                                            <InfoBoxBGStatsCard
                                                icon={"fas fa-thumbs-up"}
                                                label={"ACTIVE USERS"}
                                                value={
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        value={activeUser}
                                                        displayType="text"
                                                    />
                                                }
                                                type={"bg-white"}
                                                unit={"คน"}
                                            />
                                        </Col>
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        <Col hidden={getAgentIndex("ufa") === -1}>
                                            <InfoBoxBGStatsCard
                                                icon={"fas fa-user-plus"}
                                                label={"ลูกค้าใหม่ UFA วันนี้"}
                                                value={
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        value={newUfaUser}
                                                        displayType="text"
                                                    />
                                                }
                                                type={"bg-warning"}
                                                unit={"คน"}
                                            />
                                        </Col>
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                            <Col hidden={getAgentIndex("gclub") === -1}>
                                                <InfoBoxBGStatsCard
                                                    icon={"fas fa-user-plus"}
                                                    label={"ลูกค้า GClub ใหม่วันนี้"}
                                                    value={
                                                        <NumberFormat
                                                            thousandSeparator={true}
                                                            value={newGClubUser}
                                                            displayType="text"
                                                        />
                                                    }
                                                    type={"bg-indigo"}
                                                    unit={"คน"}
                                                />
                                            </Col>
                                    </Row>
                                    {/*</Row>*/}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {login_profile.role === "super_admin" && (
                        <Card>
                            <Card.Header style={{ backgroundColor: "rgb(91,226,208)" }}>
                                <h5>ยอดสรุปรวม(ของเวลาที่เลือก)</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row lg={12}>
                                    <Col lg={3}>
                                        <InfoBoxBGStatsCard
                                            icon={"far fa-arrow-alt-circle-down"}
                                            label={"ฝากรวม"}
                                            value={
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    value={summaryAccount.deposit}
                                                    displayType="text"
                                                />
                                            }
                                            type={"bg-success"}
                                            description={"ยอดจำนวนฝากทั้งหมดของเวลาที่เลือก"}
                                        />
                                    </Col>
                                    <Col lg={3}>
                                        <InfoBoxBGStatsCard
                                            icon={"far fa-arrow-alt-circle-up"}
                                            label={"ถอนรวม"}
                                            value={
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    value={summaryAccount.withdraw}
                                                    displayType="text"
                                                />
                                            }
                                            type={"bg-danger"}
                                            description={"ยอดจำนวนถอนทั้งหมดของเวลาที่เลือก"}
                                        />
                                    </Col>
                                    <Col lg={3}>
                                        <InfoBoxBGStatsCard
                                            icon={"far fa-arrow-alt-circle-right"}
                                            label={"โยกฝากไปถอนรวม"}
                                            value={
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    value={summaryAccount.sweepWithdraw}
                                                    displayType="text"
                                                />
                                            }
                                            type={"bg-secondary"}
                                            description={"ยอดจำนวนโยกฝากไปถอนทั้งหมดของเวลาที่เลือก"}
                                        />
                                    </Col>
                                    <Col lg={3}>
                                        <InfoBoxBGStatsCard
                                            icon={"fas fa-pause-circle"}
                                            label={"โยกถอนไปพักรวม"}
                                            value={
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    value={summaryAccount.sweepHolding}
                                                    displayType="text"
                                                />
                                            }
                                            type={"bg-primary"}
                                            description={"ยอดจำนวนโยกถอนไปพักทั้งหมดของเวลาที่เลือก"}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )}

                    {login_profile.role !== "agent" &&
                        login_profile.role !== "user_new" &&
                        login_profile.role !== "user_no_withdraw" &&
                        login_profile.role !== "normal" &&
                        login_profile.role !== "user_no_swap" && (
                            <React.Fragment>
                                <Row lg={12} md={12}>
                                    <Col lg={6} md={6}>
                                        <Card className="card-success">
                                            <Card.Header
                                                style={{ backgroundColor: "rgb(218,90,72)" }}
                                            >
                                                <h5>สรุปยอดเสียในเดือนนี้</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col lg={6} md={6}>
                                                        <InfoBoxBGStatsCard
                                                            icon={"fas fa-wallet"}
                                                            label={"ยอดเสียทั้งหมด"}
                                                            value={
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    value={summaryLost.total}
                                                                    displayType="text"
                                                                />
                                                            }
                                                            type={"bg-purple"}
                                                            // description={"ยอดเสียทั้งหมด"}
                                                        />
                                                    </Col>
                                                    <Col lg={6} md={6}>
                                                        <InfoBoxBGStatsCard
                                                            icon={"fas fa-user-tag"}
                                                            label={"ยอดเสียรับแล้ว"}
                                                            value={
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    value={summaryLost.redeem}
                                                                    displayType="text"
                                                                />
                                                            }
                                                            type={"bg-teal"}
                                                            // description={"ยอดเสียทั้งหมด"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6} md={6}>
                                                        <InfoBoxBGStatsCard
                                                            icon={"fas fa-hourglass-half"}
                                                            label={"ยอดเสียที่ยังไม่ได้รับ"}
                                                            value={
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    value={summaryLost.pending}
                                                                    displayType="text"
                                                                />
                                                            }
                                                            type={"bg-secondary"}
                                                            // description={"ยอดเสียทั้งหมด"}
                                                        />
                                                    </Col>
                                                    <Col lg={6} md={6}>
                                                        <InfoBoxBGStatsCard
                                                            icon={"far fa-hand-paper"}
                                                            label={"ยอดเสียที่ไม่สามารถรับได้"}
                                                            value={
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    value={summaryLost.failed}
                                                                    displayType="text"
                                                                />
                                                            }
                                                            type={"bg-white"}
                                                            // description={"ยอดเสียทั้งหมด"}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <Card className="card-yellow">
                                            <Card.Header>
                                                <h5>สรุปยอดเสียตามช่วงเวลา</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <InfoBoxBGStatsCard
                                                            icon={"fas fa-hand-holding-usd"}
                                                            label={"ยอดเสีย"}
                                                            value={
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    value={summaryLost.lost}
                                                                    displayType="text"
                                                                />
                                                            }
                                                            type={"bg-pink"}
                                                            // description={"ยอดเสียทั้งหมด"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <InfoBoxBGStatsCard
                                                            icon={"fas fa-comments-dollar"}
                                                            label={"โบนัส"}
                                                            value={
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    value={summaryLost.bonus}
                                                                    displayType="text"
                                                                />
                                                            }
                                                            type={"bg-cyan"}
                                                            // description={"ยอดเสียทั้งหมด"}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {
                                    login_profile.role === "super_admin" && (
                                        <Row className={"flex"}>
                                            <div className="col-12">
                                                <Card className={"card-primary"}>
                                                    <Card.Header>
                                                        <Card.Title>
                                                            <h3>ยอดฝาก/ถอนแยกตามประเภท</h3>
                                                        </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <DepositWithdrawTableByChannels />
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Row>)
                                }
                                <Row>
                                    <div className="col-md-12">
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>
                                                    <h3>ยอดฝาก - ถอนรายวัน</h3>
                                                </Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Bar
                                                    height={75}
                                                    data={chartData}
                                                    options={{
                                                        scales: {
                                                            yAxes: [{
                                                                ticks: {
                                                                    beginAtZero: true
                                                                }
                                                            }]
                                                        },
                                                        maintainAspectRatio: true,
                                                        tooltips: {
                                                            callbacks: {
                                                                label: (tooltipItem, chart) => {
                                                                    const datasetLabel =
                                                                        chart.datasets[tooltipItem.datasetIndex]
                                                                            .label || "";
                                                                    if (datasetLabel === "ยอดเงินฝาก") {
                                                                        // console.log("tooltipItem.yLabel ฝาก: ",tooltipItem.yLabel)
                                                                        const depositArray = [`${datasetLabel} : ${number_format(
                                                                            tooltipItem.yLabel,
                                                                            2
                                                                        )}`,
                                                                            `ยอดเงินฝาก true wallet : ${number_format(
                                                                                hoverData.trueWalletDeposit[
                                                                                    tooltipItem.index
                                                                                    ],
                                                                                2
                                                                            )}`,
                                                                            `ยอดเงินฝากเติมมือ : ${number_format(
                                                                                hoverData.depositManual[
                                                                                    tooltipItem.index
                                                                                    ],
                                                                                2
                                                                            )}`,]

                                                                        if(getAgentIndex("ufa") !== -1) {
                                                                            depositArray.push(`UFA : ${number_format(
                                                                                hoverData.ufaDeposit[tooltipItem.index],
                                                                                2
                                                                            )}`)
                                                                        }
                                                                        if(getAgentIndex("gclub") !== -1) {
                                                                            depositArray.push(`GCLUB : ${number_format(
                                                                                hoverData.gclubDeposit[
                                                                                    tooltipItem.index
                                                                                    ],
                                                                                2
                                                                            )}`)
                                                                        }
                                                                        return depositArray
                                                                    } else if (datasetLabel === "ยอดเงินถอน") {
                                                                        // console.log("tooltipItem.yLabel ถอน: ",tooltipItem.yLabel)

                                                                        const withdrawArray = [`${datasetLabel} : ${number_format(
                                                                            tooltipItem.yLabel,
                                                                            2
                                                                        )}`]

                                                                        if(getAgentIndex("ufa") !== -1) {
                                                                            withdrawArray.push(`UFA : ${number_format(
                                                                                hoverData.ufaWithdraw[
                                                                                    tooltipItem.index
                                                                                    ],
                                                                                2
                                                                            )}`)
                                                                        }

                                                                        if(getAgentIndex("gclub") !== -1) {
                                                                            withdrawArray.push(`GCLUB : ${number_format(
                                                                                hoverData.gclubWithdraw[
                                                                                    tooltipItem.index
                                                                                    ],
                                                                                2
                                                                            )}`)
                                                                        }
                                                                        return withdrawArray
                                                                    }
                                                                    return (
                                                                        datasetLabel +
                                                                        ":" +
                                                                        number_format(tooltipItem.yLabel, 2)
                                                                    );
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Row>

                                <Row>
                                    <div className="col-md-12">
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>
                                                    <h3>ยอดเสีย - โบนัสรายวัน</h3>
                                                </Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Bar
                                                    data={lostChartData}
                                                    height={75}
                                                    options={{
                                                        scales: {
                                                            yAxes: [{
                                                                ticks: {
                                                                    beginAtZero: true
                                                                }
                                                            }]
                                                        },
                                                        maintainAspectRatio: true,
                                                        tooltips: {
                                                            callbacks: {
                                                                label: (tooltipItem, chart) => {
                                                                    const datasetLabel =
                                                                        chart.datasets[tooltipItem.datasetIndex]
                                                                            .label || "";
                                                                    return (
                                                                        datasetLabel +
                                                                        ":" +
                                                                        number_format(tooltipItem.yLabel, 2)
                                                                    );
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Row>
                            </React.Fragment>
                        )}

                    {login_profile.role === "super_admin" && (
                        <Row>
                            <div className="col-md-12">
                                <Card>
                                    <Card.Header>
                                        <Card.Title>
                                            <h3>ถอนไปพักรายวัน</h3>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Bar
                                            height={75}
                                            data={sweepChartData}
                                            options={{
                                                scales: {
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true
                                                        }
                                                    }]
                                                },
                                                // maintainAspectRatio: true,
                                                tooltips: {
                                                    callbacks: {
                                                        label: (tooltipItem, chart) => {
                                                            const datasetLabel =
                                                                chart.datasets[tooltipItem.datasetIndex]
                                                                    .label || "";
                                                            return (
                                                                datasetLabel +
                                                                ":" +
                                                                number_format(tooltipItem.yLabel, 2)
                                                            );
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </div>
                        </Row>
                    )}

                    {login_profile.role !== "agent" &&
                        login_profile.role !== "user_new" &&
                        login_profile.role !== "user_no_withdraw" &&
                        login_profile.role !== "normal" &&
                        login_profile.role !== "user_no_swap" && (
                            <React.Fragment>
                                <Row>
                                    <div className="col-md-6">
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>
                                                    <h3>Active Customers</h3>
                                                </Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Bar
                                                    height={75}
                                                    data={activeCustomerData}
                                                    options={{
                                                        scales: {
                                                            yAxes: [{
                                                                ticks: {
                                                                    beginAtZero: true
                                                                }
                                                            }]
                                                        },
                                                        maintainAspectRatio: true,
                                                        tooltips: {
                                                            callbacks: {
                                                                label: (tooltipItem, chart) => {
                                                                    const datasetLabel =
                                                                        chart.datasets[tooltipItem.datasetIndex]
                                                                            .label || "";

                                                                    const activeCustomerArray = [`${datasetLabel} : ${number_format(
                                                                        tooltipItem.yLabel
                                                                    )}`]

                                                                    if(getAgentIndex("ufa") !== -1) {
                                                                        activeCustomerArray.push(`UFA : ${number_format(
                                                                            activeHover.ufaActive[tooltipItem.index]
                                                                        )}`)
                                                                    }

                                                                    if(getAgentIndex("gclub") !== -1) {
                                                                        activeCustomerArray.push(`GCLUB : ${number_format(
                                                                            activeHover.gclubActive[tooltipItem.index]
                                                                        )}`)
                                                                    }

                                                                    return activeCustomerArray
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-6">
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>
                                                    <h3>New Users</h3>
                                                </Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Bar
                                                    height={75}
                                                    data={newUserData}
                                                    options={{
                                                        scales: {
                                                            yAxes: [{
                                                                ticks: {
                                                                    beginAtZero: true
                                                                }
                                                            }]
                                                        },
                                                        maintainAspectRatio: true,
                                                        tooltips: {
                                                            callbacks: {
                                                                label: (tooltipItem, chart) => {
                                                                    const datasetLabel =
                                                                        chart.datasets[tooltipItem.datasetIndex]
                                                                            .label || "";
                                                                    return (
                                                                        datasetLabel +
                                                                        ":" +
                                                                        number_format(tooltipItem.yLabel, 0)
                                                                    );
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-12">
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>
                                                    <h3>สรุปใบงานทั้งหมด</h3>
                                                </Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Bar
                                                    data={workOrderData}
                                                    height={75}
                                                    options={{
                                                        scales: {
                                                            yAxes: [{
                                                                ticks: {
                                                                    beginAtZero: true
                                                                }
                                                            }]
                                                        },
                                                        maintainAspectRatio: true,
                                                        tooltips: {
                                                            callbacks: {
                                                                label: (tooltipItem, chart) => {
                                                                    //console.log( workOrderHover )
                                                                    const datasetLabel =
                                                                        chart.datasets[tooltipItem.datasetIndex]
                                                                            .label || "";
                                                                    if (datasetLabel === "จำนวนใบงานฝาก") {

                                                                        const workOrderArray = [datasetLabel +
                                                                        ":" +
                                                                        number_format(tooltipItem.yLabel, 0)]

                                                                        if(getAgentIndex("ufa") !== -1) {
                                                                            workOrderArray.push(`UFA : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa
                                                                                }`,
                                                                                `UFA Auto : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa_auto
                                                                                }`,
                                                                                `UFA Manual : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa_manual
                                                                                }`,
                                                                                `UFA Bonus : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa_bonus
                                                                                }`,
                                                                                `UFA Lost : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa_lost
                                                                                }`,
                                                                                `UFA E2P : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa_e2p
                                                                                }`,
                                                                                `UFA HENGPAY68 : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa_hengpay68
                                                                                }`,
                                                                                `UFA PAYONEX : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa_payonex
                                                                                }`,
                                                                                `UFA JOMPAY : ${
                                                                                    workOrderHover.ufaDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_ufa_jompay
                                                                                }`,
                                                                            )
                                                                        }

                                                                        if(getAgentIndex("gclub") !== -1) {
                                                                            workOrderArray.push(
                                                                                `GClub : ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub
                                                                                }`,
                                                                                `GClub Auto: ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub_auto
                                                                                }`,
                                                                                `GClub Manual: ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub_manual
                                                                                }`,
                                                                                `GClub Bonus: ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub_bonus
                                                                                }`,
                                                                                `GClub Lost: ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub_lost
                                                                                }`,
                                                                                `GClub VIZ: ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub_vizpay
                                                                                }`,
                                                                                `GClub CorePay: ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub_corepay
                                                                                }`,
                                                                                `GClub HengPay: ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub_hengpay
                                                                                }`,
                                                                                `GClub PerfectPays: ${
                                                                                    workOrderHover.gclubDeposit[
                                                                                        tooltipItem.index
                                                                                        ].deposit_gclub_perfectpays
                                                                                }`
                                                                            )
                                                                        }
                                                                        return workOrderArray
                                                                    } else if (datasetLabel === "จำนวนใบงานถอน") {
                                                                        const workOrderWithdrawArray = [datasetLabel +
                                                                        ":" +
                                                                        number_format(tooltipItem.yLabel, 0)]

                                                                        if(getAgentIndex("ufa" !==-1)){
                                                                            workOrderWithdrawArray.push(`UFA: ${
                                                                                workOrderHover.ufaWithdraw[
                                                                                    tooltipItem.index
                                                                                    ]
                                                                            }`)
                                                                        }

                                                                        if(getAgentIndex("gclub" !==-1)) {
                                                                            workOrderWithdrawArray.push(
                                                                                `GClub: ${
                                                                                    workOrderHover.gclubWithdraw[
                                                                                        tooltipItem.index
                                                                                        ]
                                                                                }`
                                                                            )
                                                                        }

                                                                        return workOrderWithdrawArray
                                                                    }
                                                                    return (
                                                                        datasetLabel +
                                                                        ":" +
                                                                        number_format(tooltipItem.yLabel, 0)
                                                                    );
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Row>

                                <Row>
                                    <div className="col-md-12">
                                        <Card className="card-yellow">
                                            <Card.Header
                                                style={{ backgroundColor: "rgb(239,195,84)" }}
                                            >
                                                <h5>Top 10 ลูกค้าที่ฝากและถอน</h5>
                                            </Card.Header>
                                            <Card.Body className="row">
                                                <Form.Row className="col-md-12">
                                                    <Form.Group
                                                        as={Col}
                                                        md="3"
                                                        controlId="validationTop10"
                                                    >
                                                        <Form.Label>ประเภทบัญชี</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            required
                                                            name="type_account_top10"
                                                            value={typeTop10Account}
                                                            onChange={(event) => {
                                                                void handleTypeTop10Account(event.target.value);
                                                            }}
                                                        >
                                                            <option value="">Please Select</option>
                                                            <option value="deposit">ฝาก</option>
                                                            <option value="withdraw">ถอน</option>
                                                            <option value="vvip">VVIP</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group
                                                        as={Col}
                                                        md="6"
                                                        controlId="validationTop10Account"
                                                    >
                                                        <Form.Label>ประเภทบัญชี</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            required
                                                            name="account_top10"
                                                            defaultValue=""
                                                            onChange={(event) => {
                                                                void handleTop10Account(event.target.value);
                                                            }}
                                                        >
                                                            <option value="">Please Select</option>
                                                            {bankSelectTop10Account.map((account, index) => (
                                                                <option key={index} value={account.id}>
                                                                    {account.bank_name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                            </Card.Body>
                                            {dataTop10 ? (
                                                <Table
                                                    responsive
                                                    hover
                                                    size="md"
                                                    className="text-center"
                                                >
                                                    <thead>
                                                    <tr data-aos="fade-down">
                                                        <th>อันดับ</th>
                                                        <th>User ID</th>
                                                        <th>ชื่อ</th>
                                                        <th>จำนวนเงิน</th>
                                                        <th>จำนวนครั้งที่โอน</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {dataTop10.map(
                                                        (
                                                            {
                                                                amount,
                                                                count,
                                                                customer_id,
                                                                name,
                                                                rank,
                                                                user_ids,
                                                            },
                                                            index
                                                        ) => (
                                                            <tr key={customer_id}>
                                                                <td>
                                                                    <h6>{rank}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{user_ids.join(", ")}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{name}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{number_format(amount, 2)}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{count}</h6>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                    </tbody>
                                                </Table>
                                            ) : (
                                                <div style={{ textAlign: "center", margin: "10px" }}>
                                                    ไม่มีข้อมูล
                                                </div>
                                            )}
                                        </Card>
                                    </div>
                                </Row>
                            </React.Fragment>
                        )}

                    <Row>
                        <div className="col-md-12">
                            <Card className="card-yellow">
                                <Card.Header style={{ backgroundColor: "rgb(234,66,134)" }}>
                                    <h5>คนที่สมัครแต่ไม่ได้ฝาก</h5>
                                </Card.Header>
                                <Card.Body className="row">
                                    {userRegisNotTransfer ? (
                                        <Table responsive hover size="md" className="text-center">
                                            <thead>
                                            <tr data-aos="fade-down">
                                                <th>#</th>
                                                <th>User ID</th>
                                                <th>วันที่สร้าง</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {userRegisNotTransfer.map(
                                                ({ user_id, created_at }, index) => (
                                                    <tr key={user_id}>
                                                        <td>
                                                            <h6>{index + 1}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{user_id}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {moment(created_at).format(
                                                                    "HH:mm:ss, DD MMMM YYYY"
                                                                )}
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div style={{ textAlign: "center", margin: "10px" }}>
                                            ไม่มีข้อมูล
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>

                    {login_profile.role !== "user_new" &&
                        login_profile.role !== "user_no_withdraw" &&
                        login_profile.role !== "normal" &&
                        login_profile.role !== "user_no_swap" && (
                            <Row>
                                <div className="col-md-12">
                                    <Card className="card-yellow">
                                        <Card.Header style={{ backgroundColor: "rgb(181,51,255)" }}>
                                            <h5>สรุปข้อมูลแยกราย AG</h5>
                                        </Card.Header>
                                        <Card.Body className="row">
                                            {agentsSummary ? (
                                                <Table
                                                    responsive
                                                    hover
                                                    size="md"
                                                    className="text-center"
                                                >
                                                    <thead>
                                                    <tr data-aos="fade-down">
                                                        <th>ชื่อ Agent</th>
                                                        <th>ลูกค้าที่ active</th>
                                                        <th>จำนวนลูกค้าใหม่</th>
                                                        <th>ลูกค้าที่สมัครแต่ไม่ฝาก</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {agentsSummary.map(
                                                        (
                                                            {
                                                                agent_name,
                                                                active_customers,
                                                                new_users,
                                                                count_customer_no_deposit,
                                                            },
                                                            index
                                                        ) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <h6>{agent_name}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{active_customers}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{new_users}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{count_customer_no_deposit}</h6>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                    </tbody>
                                                </Table>
                                            ) : (
                                                <div style={{ textAlign: "center", margin: "10px" }}>
                                                    ไม่มีข้อมูล
                                                </div>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Row>
                        )}

                    {login_profile.role === "super_admin" && (
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Card className="card-primary">
                                    <Card.Header>
                                        <h5>สรุปข้อมูลการสมัครตามช่องทาง และ Money site</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <ChannelChart />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {(login_profile.role === "super_admin" ||
                login_profile.role === "agent") && (
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Card className="card-yellow">
                            <Card.Header style={{ backgroundColor: "#e88300" }}>
                                <h5>สรุปรายการ AG</h5>
                            </Card.Header>
                            <Card.Body className="row">
                                <Form>
                                    <Row>
                                        <Form.Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <Form.Label className="ml-1">
                                                    เลือกวันที่และเวลาที่ต้องการค้นหา{" "}
                                                    <span className="text-red-500 font-bold">
                            (**
                            หลังเปลี่ยนวันที่หรือเวลากรุณากดปุ่มค้นหาข้อมูลใหม่)
                          </span>
                                                </Form.Label>
                                            </Col>
                                            <Col sm={6} md={6} lg={6}>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>วันที่</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl
                                                        type="date"
                                                        placeholder="2020-09-10"
                                                        onChange={(e) =>
                                                            setAgentsWorkOrdersDate(e.target.value)
                                                        }
                                                        defaultValue={moment(agentsWorkOrdersDate).format(
                                                            "YYYY-MM-DD"
                                                        )}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col sm={3} md={3} lg={3}>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>เวลา</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control
                                                        as="select"
                                                        name="agent_work_orders_time"
                                                        value={agentsWorkOrdersTime}
                                                        onChange={(e) =>
                                                            setAgentsWorkOrdersTime(e.target.value)
                                                        }
                                                    >
                                                        <option value="00:00">00:00</option>
                                                        <option value="12:00">12:00</option>
                                                    </Form.Control>
                                                </InputGroup>
                                            </Col>
                                            <Col xs="auto">
                                                {!loadingAgentsWorkOrder ? (
                                                    <Button
                                                        type="button"
                                                        className="ml-3"
                                                        onClick={() => getAgentsWorkOrders()}
                                                    >
                                                        ค้นหาข้อมูล
                                                    </Button>
                                                ) : (
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        กำลังค้นหา
                                                    </>
                                                )}
                                            </Col>
                                        </Form.Row>
                                    </Row>
                                </Form>
                                {!loadingAgentsWorkOrder &&
                                agentsWorkOrders &&
                                agentsWorkOrders.length !== 0 ? (
                                    <>
                                        <h3 className="font-bold mt-2">
                                            {agentsWorkOrdersDisplayText}
                                        </h3>
                                        <Table
                                            responsive
                                            hover
                                            size="md"
                                            className="text-center mt-3"
                                        >
                                            <thead>
                                            <tr data-aos="fade-down">
                                                <th>#</th>
                                                <th>เอเย่นต์</th>
                                                <th>เติม</th>
                                                <th>ถอน</th>
                                                <th>โบนัส (ไม่รวมยอดเสีย)</th>
                                                <th>ลบใบงานเติม</th>
                                                <th>ลบใบงานถอน</th>
                                                <th>ลบใบงานโบนัส (รวมยอดเสีย)</th>
                                                <th>รวมเติม (เติม+โบนัส) (ไม่นับลบ)</th>
                                                <th>รวมถอน (ไม่นับลบ)</th>
                                                <th>เครดิตคงเหลือ</th>
                                                <th>ยอดเสียเดือนที่แล้ว</th>
                                                <th>ยอดเสีย</th>
                                                <th>โบนัสโปรโมชั่นสำหรับลูกค้าใหม่</th>
                                                <th>โบนัสเดือนที่แล้วที่ไม่รวมยอดเสีย/ลูกค้าใหม่</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {agentsWorkOrders.map(
                                                (
                                                    {
                                                        agent_name,
                                                        deposit,
                                                        withdraw,
                                                        bonus,
                                                        bonus_lost,
                                                        deleted_deposit,
                                                        deleted_withdraw,
                                                        deleted_bonus,
                                                        total_deposit,
                                                        total_withdraw,
                                                        last_month_bonus_lost,
                                                        remaining_credit,
                                                        last_month_bonus_without_lost,
                                                        last_month_bonus_new_register_customer
                                                    },
                                                    index
                                                ) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <h6>{index + 1}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{agent_name}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{number_format(deposit, 2, ".", ",")}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{number_format(withdraw, 2, ".", ",")}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{number_format(bonus, 2, ".", ",")}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(deleted_deposit, 2, ".", ",")}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(deleted_withdraw, 2, ".", ",")}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(deleted_bonus, 2, ".", ",")}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(total_deposit, 2, ".", ",")}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(total_withdraw, 2, ".", ",")}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(remaining_credit, 2, ".", ",")}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(
                                                                    last_month_bonus_lost,
                                                                    2,
                                                                    ".",
                                                                    ","
                                                                )}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(bonus_lost, 2, ".", ",")}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(
                                                                    last_month_bonus_new_register_customer,
                                                                    2,
                                                                    ".",
                                                                    ","
                                                                )}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6>
                                                                {number_format(
                                                                    last_month_bonus_without_lost,
                                                                    2,
                                                                    ".",
                                                                    ","
                                                                )}
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                            </tbody>
                                        </Table>
                                    </>
                                ) : (
                                    <div style={{textAlign: "center", margin: "10px"}}></div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
}
