import React, { useEffect, useState } from "react";
import { Button, Card, Col, Fade, Form, Spinner, Table } from "react-bootstrap";
import luckydraw_api from "../../api/luckydraw";
import TableSearchLuckyDraw from "./TableSearchLuckyDraw";
import { useGlobalStore } from "../../store/globalContext";
import ModalLuckyDraw from "./ModalLuckyDraw";
import ModalLuckyDrawStats from "./ModalLuckyDrawStats";
import LuckyDrawForm from "./LuckyDrawForm";
import { useForm } from "react-hook-form";


export const defaultLuckyDrawOptions = {
    "amount": "0",
    "quota": 0,
    "deposit_back_date": 7,
    "image_url": "",
    "user_ids": [],
    "minimum_deposit_amount": 0,
    "show_amount":0,
    "color":"",
    "text_color":"",
    "win_image_url":"",
    "lose_image_url":"",
    "border_dot_color": "",
    "show_label":"",
    "chance_rate":""
};

export default function ManageLuckyDraw () {
    const [{ login_profile }, dispatch] = useGlobalStore();

    // Search state
    const [ searchData, setSearchData ] = useState( [] );
    const [ searchKeyword, setSearchKeyword ] = useState( "" );
    const [ searchButton, setSearchButton ] = useState( false );

    const handleKeyword = ( value ) => {
        setSearchKeyword( value );
    };

    const keySearchInput = async( searchData ) => {
        if ( searchData.keyword.length !== 0 && searchData.keyword.length < 2 ) {
            setSearchButton( false );
            return
        }

        // setSearchButton( true )

        // const searchData = {
        //     keyword: value,
        //     // id: "",
        //     // active: true,
        //     // "completed": false,
        //     // "deleted": false
        // }

        const response = await luckydraw_api.SEARCH_LUCKYDRAW( searchData );
        if ( response.status !== 200 ) {
            //console.log("search failed");
            setSearchButton( false )
            return
        }

        const { data } = response.data;

        if ( data !== null ) {

            let alignData = [];
            data.forEach( ( luckydraw => {
                    const align = {
                        "id": luckydraw.id,
                        "description": luckydraw.description,
                        "name": luckydraw.name,
                        "image_url": luckydraw.image_url,
                        "created_by": luckydraw.created_by,
                        "created_at": luckydraw.created_at,
                        "updated_by": luckydraw.updated_by,
                        "updated_at": luckydraw.updated_at,
                        "start_at": luckydraw.start_at,
                        "end_at": luckydraw.end_at,
                        "completed": luckydraw.completed,
                        "completed_at": luckydraw.completed_at,
                        "lucky_draw_options": luckydraw.lucky_draw_options,
                        "lucky_draw_options_archive": luckydraw.lucky_draw_options_archive,
                        "period_type": luckydraw.period_type,
                        "active": luckydraw.active,
                        "deleted": luckydraw.deleted,
                        "deleted_by": luckydraw.deleted_by,
                        "deleted_at": luckydraw.deleted_at,
                        "repeat" : luckydraw.repeat, // เพิ่มมากรณีที่จะสร้างแล้วให้รับซ้ำได้ในแต่ละวัน
                        "start_date" : luckydraw.start_date, // วันที่เริ่มต้น
                        "end_date" : luckydraw.end_date, // วันที่สิ้นสุด
                        "win_image_url" : luckydraw.win_image_url, //รูปที่แสดงหน้าลูกค้าตอนสุ่มได้
                        "lose_image_url": luckydraw.lose_image_url , // รูปที่แสดงหน้าลูกค้าตอนวืด
                        "border_dot_color": luckydraw.border_dot_color,
                        "chance_rate": luckydraw.chance_rate,
                    };
                    alignData.push( align );
                } )
            )
            setSearchData( alignData );

        }

        setSearchButton( false );
    };

    const defaultFormData = {
        name: "",
        description: "",
        image_url: "",
        period_type: "",
        lucky_draw_options: [ defaultLuckyDrawOptions ],
    };

    // // Create state
    const [ validated, setValidated ] = useState( false );

    const { register, handleSubmit, watch, errors, reset, setValue, getValues, setError, control } = useForm( {
        defaultValues: {
            name: "",
            description: "",
            image_url: "",
            period_type: "",
            lucky_draw_options: [ defaultLuckyDrawOptions ],
        },
        criteriaMode: "all"
    } );

    const createLuckyDraw = async luckyDrawData => {
        //console.log( "create lucky draw data: ", luckyDrawData );

        let luckyDrawOptions = [];
        for ( let i = 0; i < luckyDrawData.lucky_draw_options.length; i++ ) {
            let luckyDrawOption = {};
            for ( let key in luckyDrawData.lucky_draw_options[ i ] ) {
                let value = luckyDrawData.lucky_draw_options[ i ][ key ];
                let newValue;
                if ( key === "quota" || key === "deposit_back_date" || key === "minimum_deposit_amount" ) {
                    newValue = parseFloat( value );
                } else if ( key === "user_ids" ) {
                    if ( value === "" ) {
                        newValue = [];
                    } else {
                        newValue = value.split( "," );
                    }
                } else {
                    newValue = value;
                }
                luckyDrawOption[ key ] = newValue;
            }
            if ( luckyDrawOption.minimum_deposit_amount === 0 ) {
                delete luckyDrawOption.deposit_back_date
            }
            luckyDrawOptions.push( luckyDrawOption );
        }

        const request = {
            ...luckyDrawData,
            lucky_draw_options: luckyDrawOptions,
        };

        try {
            const response = await luckydraw_api.CREATE_LUCKYDRAW( request )
            console.log( "create lucky draw complete", response )
            // handleNewsSearch()
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `เพิ่มเกมชิงโชค`,
                    message: `เพิ่มเกมชิงโชคสำเร็จ`,
                }
            } );
        } catch ( e ) {
            console.log( `can't create lucky draw`, e.message );
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `เพิ่มเกมชิงโชค`,
                    message: `เพิ่มเกมชิงโชคไม่สำเร็จกรุณาลองอีกครั้ง หรือ ติดต่อ admin`,
                }
            } );
        }

        void keySearchInput( { keyword: "", id: "" } );
        reset( {
            name: "",
            description: "",
            image_url: "",
            period_type: "",
            lucky_draw_options: [ defaultLuckyDrawOptions ],
        } );
    };

    // Modal
    const [ modalInfo, setModalInfo ] = useState( {} );
    const [ isOpenModal, setIsOpenModal ] = useState( false );
    const [ errorAlertModal, setErrorAlertModal ] = useState( "" );

    const [ luckyDrawStatsData, setLuckyDrawStatsData ] = useState( {} );
    const [ isLuckyDrawStatsOpenModal, setIsLuckyDrawStatsOpenModal ] = useState( false );

    const openLuckyDrawModal = ( luckyDrawData, flag ) => {
        setModalInfo( luckyDrawData );
        setIsOpenModal( flag );
    };

    const openLuckyDrawStatsModal = ( luckyDrawData, flag ) => {
        setLuckyDrawStatsData( { id: luckyDrawData.id, name: luckyDrawData.name } );
        setIsLuckyDrawStatsOpenModal( flag );
    }

    const handleCloseModal = () => {
        // setModalInfo({
        //     ...modalInfo,
        //     lucky_draw_options: [],
        // });
        // console.log( modalInfo )
        setIsOpenModal( false )
    };

    const handleCloseStatsModal = () => {
        setIsLuckyDrawStatsOpenModal( false )
    }

    const handleEditLuckyDraw = async( { updateLuckyDraw } ) => {
        // console.log( updateLuckyDraw );
        setIsOpenModal( false );
        try {
            const request = {
                ...updateLuckyDraw,
            };

            await luckydraw_api.UPDATE_LUCKYDRAW( request );

            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `แก้ไขเกมชิงโชค`,
                    message: `แก้ไขเกมชิงโชคสำเร็จ`,
                }
            } );

            setIsOpenModal( false );
            keySearchInput( { keyword: "", id: updateLuckyDraw.id } );
        } catch ( error ) {
            console.log( "error from update lucky draw api: ", error.response.data.message );
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `แก้ไขเกมชิงโชค`,
                    message: error.response.data.message,
                }
            } );
            // setErrorAlertModal( "ขออภัยไม่สามารถแก้ไขข้อมูลได้ กรุณาตรวจสอบข้อมูลลูกค้าเพิ่มเติม" );
        }
    };

    useEffect( () => {
        void keySearchInput( { keyword: searchKeyword, id: "" } );
    }, [] );

    return (
        <section className="content pt-4 pr-4 pl-4">
            <ModalLuckyDraw
                isOpenModal={isOpenModal}
                luckyDrawData={modalInfo}
                onClose={handleCloseModal}
                onSave={handleEditLuckyDraw}
                callBackSearch={() => keySearchInput( { keyword: searchKeyword, id: "" } )}
                alertError={errorAlertModal}
            />
            <ModalLuckyDrawStats
                isOpenModal={isLuckyDrawStatsOpenModal}
                luckyDrawStatsData={luckyDrawStatsData}
                onClose={handleCloseStatsModal}
            />
            <div className="container-fluid">
                {
                    login_profile.role === "super_admin" &&
                    <Card className="card-warning">
                        <Card.Header>เพิ่มเกมชิงโชค</Card.Header>
                        <Card.Body>
                            <Form noValidate validated={validated} onSubmit={handleSubmit( createLuckyDraw )}>
                                <LuckyDrawForm register={register} errors={errors} control={control} watch={watch} setValue={setValue}/>
                                <Form.Row className="col-md-12">
                                    <Form.Group as={Col} md="3" lg="3" controlId="validation99">
                                        <Button className="btn-success" type="submit">เพิ่มเกมชิงโชค</Button>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Card.Body>
                    </Card>
                }
                <Card className="card-primary">
                    <Card.Header>ค้นหาเกมชิงโชค</Card.Header>
                    <Card.Body>
                        <Form.Row className="col-md-12">
                            <Form.Group className="col-md-6">
                                <Form.Label>พิมพ์ค้นหาเกมชิงโชค</Form.Label>
                                <Form.Control as="input"
                                              name="search_luckydraw"
                                              placeholder="eg. ชื่อเกม"
                                              defaultValue=""
                                              onChange={( e ) => handleKeyword( e.target.value )}
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="col-md-12">
                            <Button style={{ marginBottom: "10px" }}
                                    onClick={() => keySearchInput( { keyword: searchKeyword, id: "" } )}
                                    disabled={searchButton}>
                                {
                                    ( searchButton ) ?
                                        ( <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> ) : ( <>ค้นหา</> )
                                }
                            </Button>
                        </Form.Row>
                        <Form.Row className="col-md-12">
                            <Form.Group className="col-md-12">
                                <Fade in={true}>
                                    <TableSearchLuckyDraw luckyDrawSearchList={searchData}
                                                          editLuckyDrawData={openLuckyDrawModal}
                                                          viewLuckyDrawStats={openLuckyDrawStatsModal}
                                    />
                                </Fade>
                            </Form.Group>
                        </Form.Row>
                    </Card.Body>
                </Card>
            </div>
        </section>
    )
}