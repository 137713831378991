import { Button, Col, Form } from "react-bootstrap";
import React, {useEffect} from "react";
import { useFieldArray } from "react-hook-form";
import { defaultLuckyDrawOptions } from './ManageLuckyDraw';
import { number_format } from "../utils/Number";

export default function LuckyDrawOptions ( props ) {
    const { register, control, watch, errors } = props;

    const {
        fields,
        append,
        remove,
    } = useFieldArray( {
        control,
        name: "lucky_draw_options"
    } );

    const calAmountSummary = () => {
        let luckyDrawOptions = watch( "lucky_draw_options" );
        let sum = 0;
        for ( let i = 0; i < luckyDrawOptions.length; i++ ) {
            sum += (luckyDrawOptions[i]["amount"] * luckyDrawOptions[i]["quota"])
        }
        return sum;
    }

    return (
        <>
            <div className={"pb-3"}>
                <Form.Label>ตั้งค่าเกมชิงโชค</Form.Label>
                {
                    fields.map( ( item, index ) => {
                    return (
                        <div key={item.id}>
                            <Form.Row className={"pt-2 px-3"}>
                                <Form.Label className={"pr-3"}>เงื่อนไขที่ {index + 1}</Form.Label>
                                <Form.Group as={Col} md="2" lg="2">
                                    <Form.Label>จำนวนเงิน (บาท)</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.amount`}
                                        type="number"
                                        readOnly={index === 0}
                                        defaultValue={item.amount}
                                        control={control}
                                        isInvalid={errors.lucky_draw_options?.[index]?.amount}
                                        ref={register( { required: true } )}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุจำนวนเงิน
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="2" lg="2">
                                    <Form.Label>จำนวนคน</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.quota`}
                                        type="number"
                                        readOnly={index === 0}
                                        defaultValue={item.quota}
                                        control={control}
                                        isInvalid={errors.lucky_draw_options?.[index]?.quota}
                                        ref={register( { required: true } )}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุจำนวนคน
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="2" lg="2">
                                    <Form.Label>ฝากขั้นต่ำ (บาท)</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.minimum_deposit_amount`}
                                        type="number"
                                        readOnly={index === 0}
                                        defaultValue={item.minimum_deposit_amount}
                                        control={control}
                                        isInvalid={errors.lucky_draw_options?.[index]?.minimum_deposit_amount}
                                        ref={register( { required: true } )}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุจำนวนฝากขั้นต่ำ
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="2" lg="2">
                                    <Form.Label>จำนวนช่องบนกระดานหมุน</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.show_amount`}
                                        type="number"
                                        defaultValue={item.show_amount}
                                        control={control}
                                        isInvalid={errors.lucky_draw_options?.[index]?.show_amount}
                                        ref={register( { required: true, min:1, max:4, valueAsNumber: true} )}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุช่องบนกระดานหมุน(ขั้นต่ำ 1)
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {
                                    ( watch(`lucky_draw_options.${index}.minimum_deposit_amount`) !== "0" && watch(`lucky_draw_options.${index}.minimum_deposit_amount`)!== "" ) ?
                                        <Form.Group as={Col} md="2" lg="2">
                                            <Form.Label>ฝากย้อนหลัง (วัน)</Form.Label>
                                            <Form.Control
                                                name={`lucky_draw_options.${index}.deposit_back_date`}
                                                type="number"
                                                defaultValue={item.deposit_back_date}
                                                control={control}
                                                isInvalid={errors.lucky_draw_options?.[index]?.deposit_back_date}
                                                ref={register( { required: true } )}
                                            />
                                            <Form.Control.Feedback type="invalid" tooltip>
                                                กรุณาระบุจำนวนวันฝากย้อนหลัง
                                            </Form.Control.Feedback>
                                        </Form.Group> : <></>
                                }
                            </Form.Row>
                            <Form.Row className={"pt-2 pl-24"}>
                                <Form.Group as={Col} md="2" lg="2">
                                    <Form.Label>สีพื้นหลัง</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.color`}
                                        type="text"
                                        defaultValue={item?.color}
                                        control={control}
                                        isInvalid={errors.lucky_draw_options?.[index]?.color}
                                        ref={register({ required: true } )}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุสีพื้นหลัง
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="2" lg="2">
                                <Form.Label>สีตัวอักษร</Form.Label>
                                <Form.Control
                                    name={`lucky_draw_options.${index}.text_color`}
                                    type="text"
                                    defaultValue={item?.text_color}
                                    control={control}
                                    isInvalid={errors.lucky_draw_options?.[index]?.text_color}
                                    ref={register( { required: true } )}
                                />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุสีตัวอักษร
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3">
                                    <Form.Label>ข้อความแสดงบนกงล้อ</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.show_label`}
                                        type="text"
                                        readOnly={index === 0}
                                        defaultValue={
                                            (index === 0 ? 'ลุ้นใหม่โอกาสหน้า': watch(`lucky_draw_options.${index}.show_label`))
                                        }
                                        control={control}
                                        isInvalid={errors.lucky_draw_options?.[index]?.show_label}
                                        ref={register( { required: true } )}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        กรุณาระบุข้อความแสดงบนกงล้อ
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" lg="3">
                                    <Form.Label>ตัวอย่าง</Form.Label>
                                    <Form.Control
                                        name={"example_text"}
                                        type="text"
                                        defaultValue={watch(`lucky_draw_options.${index}.show_label`)}
                                        ref={register( { required: false } )}
                                        readOnly
                                        className={"text-right"}
                                        style={{
                                            color:watch(`lucky_draw_options.${index}.text_color`),
                                            backgroundColor:watch(`lucky_draw_options.${index}.color`),
                                            // borderColor:item?.border_dot_color||'green',
                                            borderColor:watch("border_dot_color"),
                                            borderWidth:'3px',
                                        }}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={"pt-2 pl-24"}>
                                <Form.Group as={Col} md="6" lg="6">
                                    <Form.Label>เจาะจง UserID</Form.Label>
                                    <Form.Control
                                        name={`lucky_draw_options.${index}.user_ids`}
                                        as="textarea"
                                        rows={4}
                                        readOnly={index === 0}
                                        defaultValue={item.user_ids}
                                        control={control}
                                        ref={register}
                                        placeholder="eg. ufyu8810012 หรือ ufyu8810012,ufyu8810013"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={"pl-24"}>
                                {(fields.length !== 1 && index !== 0) && <Button
                                    variant="secondary"
                                    className="ml-2 mr-1"
                                    onClick={() => remove( index )}>ลบ</Button>}
                                {( fields.length - 1 === index && fields.length !== 10 ) && <Button
                                    className="ml-1"
                                    onClick={() => {
                                        append( defaultLuckyDrawOptions );
                                    }}
                                >เพิ่มเงื่อนไข</Button>
                                }
                            </Form.Row>
                        </div>
                    );
                } )}
            </div>
            <Form.Row className={"pb-3"}>
                <p className={"text-red-500 font-bold"}>
                    ** สรุปเงินทั้งหมดที่แจกในเกมชิงโชค {number_format( calAmountSummary(), 2, '.', ',' )} บาท
                </p>
            </Form.Row>
        </>
    )
}