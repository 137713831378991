
export const route_config = {
  user_new: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open"
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  user_no_withdraw: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  normal: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  user_swap: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fa-sms'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
        ]
      },
      {
        name: 'จัดการข้อมูลธนาคาร',
        path: '#',
        icon: 'fas fa-search-dollar',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'เพิ่ม-ลด ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
        ],
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  advanced_admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
        ]
      },
      {
        name: 'จัดการข้อมูลธนาคาร',
        path: '#',
        icon: 'fas fa-search-dollar',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'เพิ่ม-ลด ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
        ],
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  super_admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
        ]
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
        ]
      },
      {
        name: 'จัดการข้อมูลธนาคาร',
        path: '#',
        icon: 'fas fa-search-dollar',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'เพิ่ม-ลด ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
        ],
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'Invoice',
            path: '/invoice',
            icon: 'fas fa-receipt'
          },
          {
            name: 'สรุปบิลค่าใช้จ่าย',
            path: '/invoice_summary',
            icon: 'fas fa-money-bill-wave'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  agent: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  }
}

export const route_config_fever = {
  user_new: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  user_no_withdraw: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  normal: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  user_swap: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fa-sms'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'ตรวจสอบรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'จัดการลิสต์ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fas fa-coins'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'คืนยอดเสีย Social',
            path: '/refund_social',
            icon: 'far fa-futbol'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  advanced_admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'ตรวจสอบรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'จัดการลิสต์ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fas fa-coins'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'คืนยอดเสีย Social',
            path: '/refund_social',
            icon: 'far fa-futbol'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  super_admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'ตรวจสอบรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'จัดการลิสต์ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fa-coins'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'คืนยอดเสีย Social',
            path: '/refund_social',
            icon: 'far fa-futbol'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
          {
            name: 'Invoice',
            path: '/invoice',
            icon: 'fas fa-receipt'
          },
          {
            name: 'สรุปบิลค่าใช้จ่าย',
            path: '/invoice_summary',
            icon: 'fas fa-money-bill-wave'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ],
        menu: "menu-open",
      }
    ]
  },
  agent: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  }
}

export const route_config_lucky = {
  user_new: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  user_no_withdraw: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  normal: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  user_swap: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fa-sms'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'ตรวจสอบรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'จัดการลิสต์ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fa-coins'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'คืนยอดเสีย Social',
            path: '/refund_social',
            icon: 'far fa-futbol'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  advanced_admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'ตรวจสอบรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'จัดการลิสต์ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fas fa-coins'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'คืนยอดเสีย Social',
            path: '/refund_social',
            icon: 'far fa-futbol'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  super_admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'ตรวจสอบรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'จัดการลิสต์ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fas fa-coins'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'คืนยอดเสีย Social',
            path: '/refund_social',
            icon: 'far fa-futbol'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
          {
            name: 'Invoice',
            path: '/invoice',
            icon: 'fas fa-receipt'
          },
          {
            name: 'สรุปบิลค่าใช้จ่าย',
            path: '/invoice_summary',
            icon: 'fas fa-money-bill-wave'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ],
        menu: "menu-open",
      }
    ]
  },
  agent: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  }
}

// export const route_config_lucky_old = {
//   user_new: {
//     menus: [
//       {
//         name: 'จัดการข้อมูลลูกค้า',
//         path: '#',
//         icon: 'fa-address-book',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ค้นหา/สมัครลูกค้าใหม่',
//             path: '/registeruser',
//             icon: 'fa-user-plus'
//           },
//           {
//             name: 'ตรวจสอบรายการธุรกรรม',
//             path: '/checktransactions',
//             icon: 'fas fa-chart-line'
//           },
//         ],
//         menu: "menu-open",
//       },
//       {
//         name: 'ใบงาน AUTO',
//         path: '#',
//         icon: 'fa-suitcase',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'Dashboard',
//             path: '/dashboard',
//             icon: 'fa-tachometer-alt'
//           },
//           {
//             name: 'ใบงานฝาก (AUTO)',
//             path: '/deposit_workorder',
//             icon: 'fa-arrow-circle-right'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานฝาก',
//             path: '/deposit_workorder_summary',
//             icon: 'fa-file-download'
//           },
//           {
//             name: 'True Wallet',
//             path: '/true_wallet_workorder',
//             icon: 'far fa-wallet'
//           },
//           {
//             name: 'สรุปใบงาน True Wallet',
//             path: '/true_wallet_summary_workorder',
//             icon: 'far fa-money-bill-wave'
//           },
//           {
//             name: 'คืนยอดเสียรับเอง',
//             path: '/refund_customers_accept',
//             icon: 'fas fa-exchange-alt'
//           },
//           {
//             path: '/warning',
//             name: 'มิจฉาชีพ',
//             icon: 'fa-user-secret'
//           },
//           {
//             name: 'จัดการ Lucky Draw',
//             path: '/manage_luckydraw',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'ออกจากระบบ',
//             path: '/logout',
//             icon: 'fa-sign-out-alt'
//           },
//         ]
//       },
//     ]
//   },
//   user_no_withdraw: {
//     menus: [
//       {
//         name: 'จัดการข้อมูลลูกค้า',
//         path: '#',
//         icon: 'fa-address-book',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ค้นหา/สมัครลูกค้าใหม่',
//             path: '/registeruser',
//             icon: 'fa-user-plus'
//           },
//           {
//             name: 'ตรวจสอบรายการธุรกรรม',
//             path: '/checktransactions',
//             icon: 'fas fa-chart-line'
//           },
//         ],
//         menu: "menu-open",
//       },
//       {
//         name: 'ใบงาน AUTO',
//         path: '#',
//         icon: 'fa-suitcase',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'Dashboard',
//             path: '/dashboard',
//             icon: 'fa-tachometer-alt'
//           },
//           {
//             name: 'ใบงานฝาก (AUTO)',
//             path: '/deposit_workorder',
//             icon: 'fa-arrow-circle-right'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานฝาก',
//             path: '/deposit_workorder_summary',
//             icon: 'fa-file-download'
//           },
//           {
//             name: 'โบนัส',
//             path: '/bonus_customers',
//             icon: 'fa-dollar-sign'
//           },
//           {
//             name: 'True Wallet',
//             path: '/true_wallet_workorder',
//             icon: 'far fa-wallet'
//           },
//           {
//             name: 'สรุปใบงาน True Wallet',
//             path: '/true_wallet_summary_workorder',
//             icon: 'far fa-money-bill-wave'
//           },
//           {
//             name: 'คืนยอดเสีย',
//             path: '/refund_customers',
//             icon: 'fas fa-chalkboard-teacher'
//           },
//           {
//             name: 'คืนยอดเสียรับเอง',
//             path: '/refund_customers_accept',
//             icon: 'fas fa-exchange-alt'
//           },
//           {
//             path: '/warning',
//             name: 'มิจฉาชีพ',
//             icon: 'fa-user-secret'
//           },
//           {
//             name: 'จัดการโปรโมชั่น',
//             path: '/manage_promotion',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'จัดการ Lucky Draw',
//             path: '/manage_luckydraw',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'ออกจากระบบ',
//             path: '/logout',
//             icon: 'fa-sign-out-alt'
//           },
//         ]
//       },
//     ]
//   },
//   normal: {
//     menus: [
//       {
//         name: 'จัดการข้อมูลลูกค้า',
//         path: '#',
//         icon: 'fa-address-book',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ค้นหา/สมัครลูกค้าใหม่',
//             path: '/registeruser',
//             icon: 'fa-user-plus'
//           },
//           {
//             name: 'ตรวจสอบรายการธุรกรรม',
//             path: '/checktransactions',
//             icon: 'fas fa-chart-line'
//           },
//         ],
//         menu: "menu-open",
//       },
//       {
//         name: 'ใบงาน AUTO',
//         path: '#',
//         icon: 'fa-suitcase',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'Dashboard',
//             path: '/dashboard',
//             icon: 'fa-tachometer-alt'
//           },
//           {
//             name: 'ใบงานฝาก (AUTO)',
//             path: '/deposit_workorder',
//             icon: 'fa-arrow-circle-right'
//           },
//           {
//             name: 'ใบงานถอน (AUTO)',
//             path: '/withdraw_workorder',
//             icon: 'fa-arrow-circle-left'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานฝาก',
//             path: '/deposit_workorder_summary',
//             icon: 'fa-file-download'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานถอน',
//             path: '/withdraw_workorder_summary',
//             icon: 'fa-file-upload'
//           },
//           {
//             name: 'โบนัส',
//             path: '/bonus_customers',
//             icon: 'fa-dollar-sign'
//           },
//           {
//             name: 'True Wallet',
//             path: '/true_wallet_workorder',
//             icon: 'far fa-wallet'
//           },
//           {
//             name: 'สรุปใบงาน True Wallet',
//             path: '/true_wallet_summary_workorder',
//             icon: 'far fa-money-bill-wave'
//           },
//           {
//             name: 'คืนยอดเสีย',
//             path: '/refund_customers',
//             icon: 'fas fa-chalkboard-teacher'
//           },
//           {
//             name: 'คืนยอดเสียรับเอง',
//             path: '/refund_customers_accept',
//             icon: 'fas fa-exchange-alt'
//           },
//           {
//             path: '/warning',
//             name: 'มิจฉาชีพ',
//             icon: 'fa-user-secret'
//           },
//           {
//             name: 'จัดการโปรโมชั่น',
//             path: '/manage_promotion',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'ออกจากระบบ',
//             path: '/logout',
//             icon: 'fa-sign-out-alt'
//           },
//         ]
//       },
//     ]
//   },
//   user_swap: {
//     menus: [
//       {
//         name: 'จัดการข้อมูลลูกค้า',
//         path: '#',
//         icon: 'fa-address-book',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ค้นหา/สมัครลูกค้าใหม่',
//             path: '/registeruser',
//             icon: 'fa-user-plus'
//           },
//           {
//             name: 'ตรวจสอบรายการธุรกรรม',
//             path: '/checktransactions',
//             icon: 'fas fa-chart-line'
//           },
//         ],
//         menu: "menu-open",
//       },
//       {
//         name: 'ใบงาน AUTO',
//         path: '#',
//         icon: 'fa-suitcase',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ใบงานฝาก (AUTO)',
//             path: '/deposit_workorder',
//             icon: 'fa-arrow-circle-right'
//           },
//           {
//             name: 'ใบงานถอน (AUTO)',
//             path: '/withdraw_workorder',
//             icon: 'fa-arrow-circle-left'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานฝาก',
//             path: '/deposit_workorder_summary',
//             icon: 'fa-file-download'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานถอน',
//             path: '/withdraw_workorder_summary',
//             icon: 'fa-file-upload'
//           },
//         ]
//       },
//       {
//         name: 'Admin Menu',
//         path: '#',
//         icon: 'fa-wrench',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'Dashboard',
//             path: '/dashboard',
//             icon: 'fa-tachometer-alt'
//           },
//           {
//             name: 'โยกฝากไปถอน',
//             path: '/movewithdraw_workorder',
//             icon: 'fa-arrow-circle-up'
//           },
//           {
//             name: 'โยกถอนไปพัก',
//             path: '/moveholding_workorder',
//             icon: 'fa-box nav-icon'
//           },
//           {
//             name: 'สรุปใบงานโยกทั้งหมด',
//             path: '/sweep_workorder_summary',
//             icon: 'fa-credit-card'
//           },
//           {
//             name: 'คืนยอดเสียรับเอง',
//             path: '/refund_customers_accept',
//             icon: 'fas fa-exchange-alt'
//           },
//           {
//             path: '/warning',
//             name: 'มิจฉาชีพ',
//             icon: 'fa-user-secret'
//           },
//           {
//             name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
//             path: '/manage_prospect_customer',
//             icon: 'fa-sms'
//           },
//           {
//             name: 'จัดการโปรโมชั่น',
//             path: '/manage_promotion',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'ออกจากระบบ',
//             path: '/logout',
//             icon: 'fa-sign-out-alt'
//           },
//         ]
//       }
//     ]
//   },
//   admin: {
//     menus: [
//       // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
//       {
//         name: 'จัดการข้อมูลลูกค้า',
//         path: '#',
//         icon: 'fa-address-book',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ค้นหา/สมัครลูกค้าใหม่',
//             path: '/registeruser',
//             icon: 'fa-user-plus'
//           },
//           {
//             name: 'ตรวจสอบรายการธุรกรรม',
//             path: '/checktransactions',
//             icon: 'fas fa-chart-line'
//           },
//         ],
//         menu: "menu-open",
//       },
//       {
//         name: 'ใบงาน AUTO',
//         path: '#',
//         icon: 'fa-suitcase',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ใบงานฝาก (AUTO)',
//             path: '/deposit_workorder',
//             icon: 'fa-arrow-circle-right'
//           },
//           {
//             name: 'ใบงานถอน (AUTO)',
//             path: '/withdraw_workorder',
//             icon: 'fa-arrow-circle-left'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานฝาก',
//             path: '/deposit_workorder_summary',
//             icon: 'fa-file-download'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานถอน',
//             path: '/withdraw_workorder_summary',
//             icon: 'fa-file-upload'
//           },
//           {
//             name: 'ตรวจสอบรายการเดินบัญชี',
//             path: '/manage_bank_statements',
//             icon: 'fas fa-money-check'
//           },
//         ]
//       },
//       {
//         name: 'Admin Menu',
//         path: '#',
//         icon: 'fa-wrench',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'Dashboard',
//             path: '/dashboard',
//             icon: 'fa-tachometer-alt'
//           },
//           {
//             name: 'โยกฝากไปถอน',
//             path: '/movewithdraw_workorder',
//             icon: 'fa-arrow-circle-up'
//           },
//           {
//             name: 'โยกถอนไปพัก',
//             path: '/moveholding_workorder',
//             icon: 'fa-box nav-icon'
//           },
//           {
//             name: 'สรุปใบงานโยกทั้งหมด',
//             path: '/sweep_workorder_summary',
//             icon: 'fa-credit-card'
//           },
//           {
//             name: 'จัดการลิสต์ธนาคาร',
//             path: '/manage_banks',
//             icon: 'fa-file-alt'
//           },
//           {
//             name: 'ดูรายละเอียดธนาคาร',
//             path: '/manage_banks_detail',
//             icon: 'fa-cog'
//           },
//           {
//             name: 'QR ล็อกอินธนาคาร',
//             path: '/qr_session',
//             icon: 'fas fa-qrcode'
//           },
//           {
//             name: 'เพิ่ม/ลด CC user',
//             path: '/manage_cc_users',
//             icon: 'fa-users'
//           },
//           {
//             name: 'จัดการหน้า Agent',
//             path: '/manage_agent',
//             icon: 'fa-cog'
//           },
//           // {
//           //   name: 'เติมเครดิต gclub',
//           //   path: '/manage_topup_credit_gclub',
//           //   icon: 'fa-coins'
//           // },
//           {
//             name: 'ข่าวสารสำหรับลูกค้า',
//             path: '/news_upload',
//             icon: 'fas fa-newspaper'
//           },
//           {
//             name: 'โบนัส',
//             path: '/bonus_customers',
//             icon: 'fa-dollar-sign'
//           },
//           {
//             name: 'True Wallet',
//             path: '/true_wallet_workorder',
//             icon: 'far fa-wallet'
//           },
//           {
//             name: 'สรุปใบงาน True Wallet',
//             path: '/true_wallet_summary_workorder',
//             icon: 'far fa-money-bill-wave'
//           },
//           {
//             name: 'คืนยอดเสีย',
//             path: '/refund_customers',
//             icon: 'fas fa-chalkboard-teacher'
//           },
//           {
//             name: 'คืนยอดเสียรับเอง',
//             path: '/refund_customers_accept',
//             icon: 'fas fa-exchange-alt'
//           },
//           {
//             name: 'คืนยอดเสีย Social',
//             path: '/refund_social',
//             icon: 'far fa-futbol'
//           },
//           {
//             path: '/warning',
//             name: 'มิจฉาชีพ',
//             icon: 'fa-user-secret'
//           },
//           {
//             name: 'sms',
//             path: '/sms_reader',
//             icon: 'fa-sms'
//           },
//           {
//             name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
//             path: '/manage_prospect_customer',
//             icon: 'fas fa-search'
//           },
//           {
//             name: 'ค้นหา statement ผิดปกติ',
//             path: '/manage_abnormal_statement',
//             icon: 'fas fa-table'
//           },
//           {
//             name: 'จัดการโปรโมชั่น',
//             path: '/manage_promotion',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'ออกจากระบบ',
//             path: '/logout',
//             icon: 'fa-sign-out-alt'
//           },
//         ]
//       }
//     ]
//   },
//   advanced_admin: {
//     menus: [
//       // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
//       {
//         name: 'จัดการข้อมูลลูกค้า',
//         path: '#',
//         icon: 'fa-address-book',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ค้นหา/สมัครลูกค้าใหม่',
//             path: '/registeruser',
//             icon: 'fa-user-plus'
//           },
//           {
//             name: 'ตรวจสอบรายการธุรกรรม',
//             path: '/checktransactions',
//             icon: 'fas fa-chart-line'
//           },
//         ],
//         menu: "menu-open",
//       },
//       {
//         name: 'ใบงาน AUTO',
//         path: '#',
//         icon: 'fa-suitcase',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ใบงานฝาก (AUTO)',
//             path: '/deposit_workorder',
//             icon: 'fa-arrow-circle-right'
//           },
//           {
//             name: 'ใบงานถอน (AUTO)',
//             path: '/withdraw_workorder',
//             icon: 'fa-arrow-circle-left'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานฝาก',
//             path: '/deposit_workorder_summary',
//             icon: 'fa-file-download'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานถอน',
//             path: '/withdraw_workorder_summary',
//             icon: 'fa-file-upload'
//           },
//           {
//             name: 'ตรวจสอบรายการเดินบัญชี',
//             path: '/manage_bank_statements',
//             icon: 'fas fa-money-check'
//           },
//         ]
//       },
//       {
//         name: 'Admin Menu',
//         path: '#',
//         icon: 'fa-wrench',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'Dashboard',
//             path: '/dashboard',
//             icon: 'fa-tachometer-alt'
//           },
//           {
//             name: 'โยกฝากไปถอน',
//             path: '/movewithdraw_workorder',
//             icon: 'fa-arrow-circle-up'
//           },
//           {
//             name: 'โยกถอนไปพัก',
//             path: '/moveholding_workorder',
//             icon: 'fa-box nav-icon'
//           },
//           {
//             name: 'สรุปใบงานโยกทั้งหมด',
//             path: '/sweep_workorder_summary',
//             icon: 'fa-credit-card'
//           },
//           {
//             name: 'จัดการลิสต์ธนาคาร',
//             path: '/manage_banks',
//             icon: 'fa-file-alt'
//           },
//           {
//             name: 'ดูรายละเอียดธนาคาร',
//             path: '/manage_banks_detail',
//             icon: 'fa-cog'
//           },
//           {
//             name: 'QR ล็อกอินธนาคาร',
//             path: '/qr_session',
//             icon: 'fas fa-qrcode'
//           },
//           {
//             name: 'เพิ่ม/ลด CC user',
//             path: '/manage_cc_users',
//             icon: 'fa-users'
//           },
//           {
//             name: 'จัดการหน้า Agent',
//             path: '/manage_agent',
//             icon: 'fa-cog'
//           },
//           // {
//           //   name: 'เติมเครดิต gclub',
//           //   path: '/manage_topup_credit_gclub',
//           //   icon: 'fa-coins'
//           // },
//           {
//             name: 'ข่าวสารสำหรับลูกค้า',
//             path: '/news_upload',
//             icon: 'fas fa-newspaper'
//           },
//           {
//             name: 'โบนัส',
//             path: '/bonus_customers',
//             icon: 'fa-dollar-sign'
//           },
//           {
//             name: 'True Wallet',
//             path: '/true_wallet_workorder',
//             icon: 'far fa-wallet'
//           },
//           {
//             name: 'สรุปใบงาน True Wallet',
//             path: '/true_wallet_summary_workorder',
//             icon: 'far fa-money-bill-wave'
//           },
//           {
//             name: 'คืนยอดเสีย',
//             path: '/refund_customers',
//             icon: 'fas fa-chalkboard-teacher'
//           },
//           {
//             name: 'คืนยอดเสียรับเอง',
//             path: '/refund_customers_accept',
//             icon: 'fas fa-exchange-alt'
//           },
//           {
//             name: 'คืนยอดเสีย Social',
//             path: '/refund_social',
//             icon: 'far fa-futbol'
//           },
//           {
//             path: '/warning',
//             name: 'มิจฉาชีพ',
//             icon: 'fa-user-secret'
//           },
//           {
//             name: 'sms',
//             path: '/sms_reader',
//             icon: 'fa-sms'
//           },
//           {
//             name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
//             path: '/manage_prospect_customer',
//             icon: 'fas fa-search'
//           },
//           {
//             name: 'ค้นหา statement ผิดปกติ',
//             path: '/manage_abnormal_statement',
//             icon: 'fas fa-table'
//           },
//           {
//             name: 'จัดการโปรโมชั่น',
//             path: '/manage_promotion',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'ออกจากระบบ',
//             path: '/logout',
//             icon: 'fa-sign-out-alt'
//           },
//         ]
//       }
//     ]
//   },
//   super_admin: {
//     menus: [
//       // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
//       {
//         name: 'จัดการข้อมูลลูกค้า',
//         path: '#',
//         icon: 'fa-address-book',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ค้นหา/สมัครลูกค้าใหม่',
//             path: '/registeruser',
//             icon: 'fa-user-plus'
//           },
//           {
//             name: 'ตรวจสอบรายการธุรกรรม',
//             path: '/checktransactions',
//             icon: 'fas fa-chart-line'
//           },
//         ],
//         menu: "menu-open",
//       },
//       {
//         name: 'ใบงาน AUTO',
//         path: '#',
//         icon: 'fa-suitcase',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ใบงานฝาก (AUTO)',
//             path: '/deposit_workorder',
//             icon: 'fa-arrow-circle-right'
//           },
//           {
//             name: 'ใบงานถอน (AUTO)',
//             path: '/withdraw_workorder',
//             icon: 'fa-arrow-circle-left'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานฝาก',
//             path: '/deposit_workorder_summary',
//             icon: 'fa-file-download'
//           },
//           {
//             name: 'สรุปข้อมูลใบงานถอน',
//             path: '/withdraw_workorder_summary',
//             icon: 'fa-file-upload'
//           },
//           {
//             name: 'ตรวจสอบรายการเดินบัญชี',
//             path: '/manage_bank_statements',
//             icon: 'fas fa-money-check'
//           },
//         ]
//       },
//       {
//         name: 'Admin Menu',
//         path: '#',
//         icon: 'fa-wrench',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'Dashboard',
//             path: '/dashboard',
//             icon: 'fa-tachometer-alt'
//           },
//           {
//             name: 'โยกฝากไปถอน',
//             path: '/movewithdraw_workorder',
//             icon: 'fa-arrow-circle-up'
//           },
//           {
//             name: 'โยกถอนไปพัก',
//             path: '/moveholding_workorder',
//             icon: 'fa-box nav-icon'
//           },
//           {
//             name: 'สรุปใบงานโยกทั้งหมด',
//             path: '/sweep_workorder_summary',
//             icon: 'fa-credit-card'
//           },
//           {
//             name: 'จัดการลิสต์ธนาคาร',
//             path: '/manage_banks',
//             icon: 'fa-file-alt'
//           },
//           {
//             name: 'ดูรายละเอียดธนาคาร',
//             path: '/manage_banks_detail',
//             icon: 'fa-cog'
//           },
//           {
//             name: 'QR ล็อกอินธนาคาร',
//             path: '/qr_session',
//             icon: 'fas fa-qrcode'
//           },
//           {
//             name: 'เพิ่ม/ลด CC user',
//             path: '/manage_cc_users',
//             icon: 'fa-users'
//           },
//           {
//             name: 'จัดการหน้า Agent',
//             path: '/manage_agent',
//             icon: 'fa-cog'
//           },
//           // {
//           //   name: 'เติมเครดิต gclub',
//           //   path: '/manage_topup_credit_gclub',
//           //   icon: 'fa-coins'
//           // },
//           {
//             name: 'ข่าวสารสำหรับลูกค้า',
//             path: '/news_upload',
//             icon: 'fas fa-newspaper'
//           },
//           {
//             name: 'โบนัส',
//             path: '/bonus_customers',
//             icon: 'fa-dollar-sign'
//           },
//           {
//             name: 'True Wallet',
//             path: '/true_wallet_workorder',
//             icon: 'far fa-wallet'
//           },
//           {
//             name: 'สรุปใบงาน True Wallet',
//             path: '/true_wallet_summary_workorder',
//             icon: 'far fa-money-bill-wave'
//           },
//           {
//             name: 'คืนยอดเสีย',
//             path: '/refund_customers',
//             icon: 'fas fa-chalkboard-teacher'
//           },
//           {
//             name: 'คืนยอดเสียรับเอง',
//             path: '/refund_customers_accept',
//             icon: 'fas fa-exchange-alt'
//           },
//           {
//             name: 'คืนยอดเสีย Social',
//             path: '/refund_social',
//             icon: 'far fa-futbol'
//           },
//           {
//             path: '/warning',
//             name: 'มิจฉาชีพ',
//             icon: 'fa-user-secret'
//           },
//           {
//             name: 'sms',
//             path: '/sms_reader',
//             icon: 'fa-sms'
//           },
//           {
//             name: 'Invoice',
//             path: '/invoice',
//             icon: 'fas fa-receipt'
//           },
//           {
//             name: 'สรุปบิลค่าใช้จ่าย',
//             path: '/invoice_summary',
//             icon: 'fas fa-money-bill-wave'
//           },
//           {
//             name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
//             path: '/manage_prospect_customer',
//             icon: 'fas fa-search'
//           },
//           {
//             name: 'ค้นหา statement ผิดปกติ',
//             path: '/manage_abnormal_statement',
//             icon: 'fas fa-table'
//           },
//           {
//             name: 'จัดการโปรโมชั่น',
//             path: '/manage_promotion',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'ออกจากระบบ',
//             path: '/logout',
//             icon: 'fa-sign-out-alt'
//           },
//         ],
//         menu: "menu-open",
//       }
//     ]
//   },
//   agent: {
//     menus: [
//       // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
//       {
//         name: 'จัดการข้อมูลลูกค้า',
//         path: '#',
//         icon: 'fa-address-book',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'ค้นหา/สมัครลูกค้าใหม่',
//             path: '/registeruser',
//             icon: 'fa-user-plus'
//           },
//           {
//             name: 'ตรวจสอบรายการธุรกรรม',
//             path: '/checktransactions',
//             icon: 'fas fa-chart-line'
//           },
//         ],
//         menu: "menu-open",
//       },
//       {
//         name: 'Admin Menu',
//         path: '#',
//         icon: 'fa-wrench',
//         submenu_flag: true,
//         sub_menu_list: [
//           {
//             name: 'Dashboard',
//             path: '/dashboard',
//             icon: 'fa-tachometer-alt'
//           },
//           {
//             name: 'ข่าวสารสำหรับลูกค้า',
//             path: '/news_upload',
//             icon: 'fas fa-newspaper'
//           },
//           {
//             name: 'จัดการโปรโมชั่น',
//             path: '/manage_promotion',
//             icon: 'fas fa-bullhorn'
//           },
//           {
//             name: 'ออกจากระบบ',
//             path: '/logout',
//             icon: 'fa-sign-out-alt'
//           },
//         ]
//       }
//     ]
//   }
// }

export const route_config_pgadmin = {
  normal: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      },
    ]
  },
  user_swap: {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fa-sms'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'ตรวจสอบรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check'
          },
        ]
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'จัดการลิสต์ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fas fa-coins'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'คืนยอดเสีย Social',
            path: '/refund_social',
            icon: 'far fa-futbol'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  },
  super_admin: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search'
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret'
          }
        ]
      },
      {
        name: 'ใบงาน AUTO',
        path: '#',
        icon: 'fa-suitcase',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right'
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left'
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download'
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload'
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up'
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon'
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card'
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign'
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet'
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave'
          },
          {
            name: 'ตรวจสอบรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check'
          },
        ]
      },
      {
        name: 'จัดการข้อมูลธนาคาร',
        path: '#',
        icon: 'fas fa-search-dollar',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'เพิ่ม-ลด ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt'
          },
          {
            name: 'QR ล็อกอินธนาคาร',
            path: '/qr_session',
            icon: 'fas fa-qrcode'
          },
          {
            name: 'sms',
            path: '/sms_reader',
            icon: 'fa-sms'
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog'
          },
        ],
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users'
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog'
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fas fa-coins'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'ลิงค์รับทรัพย์',
            path: '/affiliate_customers',
            icon: 'fas fa-user-friends'
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher'
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt'
          },
          {
            name: 'คืนยอดเสีย Social',
            path: '/refund_social',
            icon: 'fa fa-futbol'
          },
          {
            name: 'Invoice',
            path: '/invoice',
            icon: 'fas fa-receipt'
          },
          {
            name: 'สรุปบิลค่าใช้จ่าย',
            path: '/invoice_summary',
            icon: 'fas fa-money-bill-wave'
          },
          {
            name: 'จัดการโปรโมชั่น',
            path: '/manage_promotion',
            icon: 'fas fa-bullhorn'
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table'
          },
          {
            name: 'Monitoring Bank Account',
            path: '/monitor_bank_account',
            icon: 'fas fa-eye'
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-dice-five'
          },
          {
            name: 'รวมสลิป',
            path: '/manage_slip_allteam',
            icon: 'fas fa-money-check'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ],
        menu: "menu-open"
      }
    ]
  },
  agent: {
    menus: [
      // { name: 'Dashboard', submenu_flag: false, path: '/dashboard', icon: 'fa-tachometer-alt' },
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus'
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line'
          },
        ],
        menu: "menu-open",
      },
      {
        name: 'Admin Menu',
        path: '#',
        icon: 'fa-wrench',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt'
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper'
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt'
          },
        ]
      }
    ]
  }
}

export const route_simple_config = {
    menus: [
      {
        name: 'จัดการข้อมูลลูกค้า',
        path: '#',
        icon: 'fa-address-book',
        submenu_flag: true,
        sub_menu_list: [
          {
            name: 'ค้นหา/สมัครลูกค้าใหม่',
            path: '/registeruser',
            icon: 'fa-user-plus',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'ตรวจสอบรายการธุรกรรม',
            path: '/checktransactions',
            icon: 'fas fa-chart-line',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
            path: '/manage_prospect_customer',
            icon: 'fas fa-search',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            path: '/warning',
            name: 'มิจฉาชีพ',
            icon: 'fa-user-secret',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'ใบงานฝาก (AUTO)',
            path: '/deposit_workorder',
            icon: 'fa-arrow-circle-right',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'ใบงานถอน (AUTO)',
            path: '/withdraw_workorder',
            icon: 'fa-arrow-circle-left',
            allow_role:["user_new", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'สรุปข้อมูลใบงานฝาก',
            path: '/deposit_workorder_summary',
            icon: 'fa-file-download',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'สรุปข้อมูลใบงานถอน',
            path: '/withdraw_workorder_summary',
            icon: 'fa-file-upload',
            allow_role:["user_new", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'โยกฝากไปถอน',
            path: '/movewithdraw_workorder',
            icon: 'fa-arrow-circle-up',
            allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'โยกถอนไปพัก',
            path: '/moveholding_workorder',
            icon: 'fa-box nav-icon',
            allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'สรุปใบงานโยกทั้งหมด',
            path: '/sweep_workorder_summary',
            icon: 'fa-credit-card',
            allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'โบนัส',
            path: '/bonus_customers',
            icon: 'fa-dollar-sign',
            allow_role:["normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'True Wallet',
            path: '/true_wallet_workorder',
            icon: 'far fa-wallet',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'สรุปใบงาน True Wallet',
            path: '/true_wallet_summary_workorder',
            icon: 'far fa-money-bill-wave',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'ตรวจรายการเดินบัญชี',
            path: '/manage_bank_statements',
            icon: 'fas fa-money-check',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'เพิ่ม-ลด ธนาคาร',
            path: '/manage_banks',
            icon: 'fa-file-alt',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'ดูรายละเอียดธนาคาร',
            path: '/manage_banks_detail',
            icon: 'fa-cog',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-tachometer-alt',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'เพิ่ม/ลด CC user',
            path: '/manage_cc_users',
            icon: 'fa-users',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'จัดการหน้า Agent',
            path: '/manage_agent',
            icon: 'fa-cog',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'เติมเครดิต gclub',
            path: '/manage_topup_credit_gclub',
            icon: 'fas fa-coins',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"],
            allow_fever: true
          },
          {
            name: 'ข่าวสารสำหรับลูกค้า',
            path: '/news_upload',
            icon: 'fas fa-newspaper',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'คืนยอดเสีย',
            path: '/refund_customers',
            icon: 'fas fa-chalkboard-teacher',
            allow_role:["normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'คืนยอดเสียรับเอง',
            path: '/refund_customers_accept',
            icon: 'fas fa-exchange-alt',
            allow_role:["normal", "admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'ลิงค์รับทรัพย์',
            path: '/affiliate_customers',
            icon: 'fas fa-user-friends',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'Invoice',
            path: '/invoice',
            icon: 'fas fa-receipt',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"]
          },
          {
            name: 'สรุปบิลค่าใช้จ่าย',
            path: '/invoice_summary',
            icon: 'fas fa-money-bill-wave',
            allow_role:["super_admin"],
            allow_fever: true
          },
          {
            name: 'ค้นหา statement ผิดปกติ',
            path: '/manage_abnormal_statement',
            icon: 'fas fa-table',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"],
          },
          {
            name: 'Monitoring Bank Account',
            path: '/monitor_bank_account',
            icon: 'fas fa-eye',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"],
            allow_fever: true
          },
          {
            name: 'จัดการ Lucky Draw',
            path: '/manage_luckydraw',
            icon: 'fas fa-dice-five',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"],
            allow_fever: true
          },
          {
            name: 'รวมสลิป',
            path: '/manage_slip_allteam',
            icon: 'fas fa-money-check',
            allow_role:["admin", "advanced_admin", "agent", "super_admin"],
            allow_fever: true
          },
          {
            name: 'ออกจากระบบ',
            path: '/logout',
            icon: 'fa-sign-out-alt',
            allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"],
          },
        ],
        menu: "menu-open",
      },
    ]
}

export const route_multiple_config = {
  menus: [
    {
      name: 'จัดการข้อมูลลูกค้า',
      path: '#',
      icon: 'fa-address-book',
      submenu_flag: true,
      sub_menu_list: [
        {
          name: 'ค้นหา/สมัครลูกค้าใหม่',
          path: '/registeruser',
          icon: 'fa-user-plus',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ตรวจสอบรายการธุรกรรม',
          path: '/checktransactions',
          icon: 'fas fa-chart-line',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
          path: '/manage_prospect_customer',
          icon: 'fas fa-search',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          path: '/warning',
          name: 'มิจฉาชีพ',
          icon: 'fa-user-secret',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },

        {
          name: 'ตรวจรายการเดินบัญชี',
          path: '/manage_bank_statements',
          icon: 'fas fa-money-check',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'เติมเครดิต gclub',
          path: '/manage_topup_credit_gclub',
          icon: 'fas fa-coins',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"],
          allow_fever: true
        },
      ],
      menu: "menu-open",
    },
    {
      name: 'ใบงาน AUTO',
      path: '#',
      icon: 'fa-suitcase',
      submenu_flag: true,
      sub_menu_list: [
        {
          name: 'ใบงานฝาก (AUTO)',
          path: '/deposit_workorder',
          icon: 'fa-arrow-circle-right',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ใบงานถอน (AUTO)',
          path: '/withdraw_workorder',
          icon: 'fa-arrow-circle-left',
          allow_role:["user_new", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปข้อมูลใบงานฝาก',
          path: '/deposit_workorder_summary',
          icon: 'fa-file-download',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปข้อมูลใบงานถอน',
          path: '/withdraw_workorder_summary',
          icon: 'fa-file-upload',
          allow_role:["user_new", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'โยกฝากไปถอน',
          path: '/movewithdraw_workorder',
          icon: 'fa-arrow-circle-up',
          allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'โยกถอนไปพัก',
          path: '/moveholding_workorder',
          icon: 'fa-box nav-icon',
          allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปใบงานโยกทั้งหมด',
          path: '/sweep_workorder_summary',
          icon: 'fa-credit-card',
          allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'โบนัส',
          path: '/bonus_customers',
          icon: 'fa-dollar-sign',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'True Wallet',
          path: '/true_wallet_workorder',
          icon: 'far fa-wallet',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปใบงาน True Wallet',
          path: '/true_wallet_summary_workorder',
          icon: 'far fa-money-bill-wave',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'คืนยอดเสีย',
          path: '/refund_customers',
          icon: 'fas fa-chalkboard-teacher',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'คืนยอดเสียรับเอง',
          path: '/refund_customers_accept',
          icon: 'fas fa-exchange-alt',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'จัดการเงินลูกค้า',
          path: '/manage_customer_game_cash',
          icon: 'fas fa-cash-register',
          allow_role:["admin", "advanced_admin", "super_admin"],
          allow_fever: true
        },
      ]
    },
    {
      name: 'จัดการข้อมูลธนาคาร',
      path: '#',
      icon: 'fas fa-search-dollar',
      submenu_flag: true,
      sub_menu_list: [
        {
          name: 'เพิ่ม-ลด ธนาคาร',
          path: '/manage_banks',
          icon: 'fa-file-alt',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ดูรายละเอียดธนาคาร',
          path: '/manage_banks_detail',
          icon: 'fa-cog',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
      ],
    },
    {
      name: 'Admin Menu',
      path: '#',
      icon: 'fa-wrench',
      submenu_flag: true,
      sub_menu_list: [
        {
          name: 'Dashboard',
          path: '/dashboard',
          icon: 'fa-tachometer-alt',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'เพิ่ม/ลด CC user',
          path: '/manage_cc_users',
          icon: 'fa-users',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'จัดการหน้า Agent',
          path: '/manage_agent',
          icon: 'fa-cog',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ข่าวสารสำหรับลูกค้า',
          path: '/news_upload',
          icon: 'fas fa-newspaper',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ลิงค์รับทรัพย์',
          path: '/affiliate_customers',
          icon: 'fas fa-user-friends',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'Invoice',
          path: '/invoice',
          icon: 'fas fa-receipt',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปบิลค่าใช้จ่าย',
          path: '/invoice_summary',
          icon: 'fas fa-money-bill-wave',
          allow_role:["super_admin"],
        },
        {
          name: 'ค้นหา statement ผิดปกติ',
          path: '/manage_abnormal_statement',
          icon: 'fas fa-table',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"],
        },
        {
          name: 'Monitoring Bank Account',
          path: '/monitor_bank_account',
          icon: 'fas fa-eye',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"],
          allow_fever: true
        },
        {
          name: 'จัดการ Lucky Draw',
          path: '/manage_luckydraw',
          icon: 'fas fa-dice-five',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"],
          allow_fever: true
        },
        {
          name: 'รวมสลิป',
          path: '/manage_slip_allteam',
          icon: 'fas fa-money-check',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"],
          allow_fever: true
        },
      ],
    }]
}

export const route_multiple_config_no_affiliate = {
  menus: [
    {
      name: 'จัดการข้อมูลลูกค้า',
      path: '#',
      icon: 'fa-address-book',
      submenu_flag: true,
      sub_menu_list: [
        {
          name: 'ค้นหา/สมัครลูกค้าใหม่',
          path: '/registeruser',
          icon: 'fa-user-plus',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ตรวจสอบรายการธุรกรรม',
          path: '/checktransactions',
          icon: 'fas fa-chart-line',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ค้นหาลูกค้าที่ยังไม่สมัคร',
          path: '/manage_prospect_customer',
          icon: 'fas fa-search',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          path: '/warning',
          name: 'มิจฉาชีพ',
          icon: 'fa-user-secret',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ตรวจรายการเดินบัญชี',
          path: '/manage_bank_statements',
          icon: 'fas fa-money-check',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'เติมเครดิต gclub',
          path: '/manage_topup_credit_gclub',
          icon: 'fas fa-coins',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"],
          allow_fever: true
        },
        {
          name: 'จัดการเงินลูกค้า',
          path: '/manage_customer_game_cash',
          icon: 'fas fa-cash-register',
          allow_role:["admin", "advanced_admin", "super_admin"],
          allow_fever: true
        },
      ],
      menu: "menu-open",
    },
    {
      name: 'ใบงาน AUTO',
      path: '#',
      icon: 'fa-suitcase',
      submenu_flag: true,
      sub_menu_list: [
        {
          name: 'ใบงานฝาก (AUTO)',
          path: '/deposit_workorder',
          icon: 'fa-arrow-circle-right',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ใบงานถอน (AUTO)',
          path: '/withdraw_workorder',
          icon: 'fa-arrow-circle-left',
          allow_role:["user_new", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปข้อมูลใบงานฝาก',
          path: '/deposit_workorder_summary',
          icon: 'fa-file-download',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปข้อมูลใบงานถอน',
          path: '/withdraw_workorder_summary',
          icon: 'fa-file-upload',
          allow_role:["user_new", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'โยกฝากไปถอน',
          path: '/movewithdraw_workorder',
          icon: 'fa-arrow-circle-up',
          allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'โยกถอนไปพัก',
          path: '/moveholding_workorder',
          icon: 'fa-box nav-icon',
          allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปใบงานโยกทั้งหมด',
          path: '/sweep_workorder_summary',
          icon: 'fa-credit-card',
          allow_role:["user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'โบนัส',
          path: '/bonus_customers',
          icon: 'fa-dollar-sign',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'True Wallet',
          path: '/true_wallet_workorder',
          icon: 'far fa-wallet',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปใบงาน True Wallet',
          path: '/true_wallet_summary_workorder',
          icon: 'far fa-money-bill-wave',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'คืนยอดเสีย',
          path: '/refund_customers',
          icon: 'fas fa-chalkboard-teacher',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'คืนยอดเสียรับเอง',
          path: '/refund_customers_accept',
          icon: 'fas fa-exchange-alt',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
      ]
    },
    {
      name: 'จัดการข้อมูลธนาคาร',
      path: '#',
      icon: 'fas fa-search-dollar',
      submenu_flag: true,
      sub_menu_list: [
        {
          name: 'เพิ่ม-ลด ธนาคาร',
          path: '/manage_banks',
          icon: 'fa-file-alt',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ดูรายละเอียดธนาคาร',
          path: '/manage_banks_detail',
          icon: 'fa-cog',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
      ],
    },
    {
      name: 'Admin Menu',
      path: '#',
      icon: 'fa-wrench',
      submenu_flag: true,
      sub_menu_list: [
        {
          name: 'Dashboard',
          path: '/dashboard',
          icon: 'fa-tachometer-alt',
          allow_role:["user_new", "user_no_withdraw", "user_swap", "normal", "admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'เพิ่ม/ลด CC user',
          path: '/manage_cc_users',
          icon: 'fa-users',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'จัดการหน้า Agent',
          path: '/manage_agent',
          icon: 'fa-cog',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'ข่าวสารสำหรับลูกค้า',
          path: '/news_upload',
          icon: 'fas fa-newspaper',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'Invoice',
          path: '/invoice',
          icon: 'fas fa-receipt',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"]
        },
        {
          name: 'สรุปบิลค่าใช้จ่าย',
          path: '/invoice_summary',
          icon: 'fas fa-money-bill-wave',
          allow_role:["super_admin"],
        },
        {
          name: 'ค้นหา statement ผิดปกติ',
          path: '/manage_abnormal_statement',
          icon: 'fas fa-table',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"],
        },
        {
          name: 'Monitoring Bank Account',
          path: '/monitor_bank_account',
          icon: 'fas fa-eye',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"],
          allow_fever: true
        },
        {
          name: 'จัดการ Lucky Draw',
          path: '/manage_luckydraw',
          icon: 'fas fa-dice-five',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"],
          allow_fever: true
        },
        {
          name: 'รวมสลิป',
          path: '/manage_slip_allteam',
          icon: 'fas fa-money-check',
          allow_role:["admin", "advanced_admin", "agent", "super_admin"],
          allow_fever: true
        },
      ],
    }]
}
