const api = process.env.REACT_APP_API_PATH;
const localURL = `https://${api}`;
const localWSURL = `wss://${api}`;

export default {
  NODE_ENV: "uat",
  LOGIN: false,
  LOGIN_API: `${localURL}/login`,
  LOGIN_CHECK: `${localURL}/check`,
  GET_QR_AUTH: `${localURL}/2fa_inquiry`,
  CONFIRM_QR_AUTH: `${localURL}/2fa_confirm`,
  CONFIRM_QR_AUTH_V2: `${localURL}/2fa_confirm_v2`,
  WEBSOCKET_LOCAL: `${localWSURL}/ws`,
  TEST_API2: `${localURL}`,
  UFA_ACCESS_TOKEN: "UFAFEVERADMINMOTHERFUCKER4444",
  THAI_BANK_API: `${localURL}/banking/thai_banks`,
  GET_AGENT_LIST: `${localURL}/agent/list`,
  SEARCH_CUSTOMER: `${localURL}/customer/search`,
  CREATE_CUSTOMER: `${localURL}/customer/register`,
  UPDATE_CUSTOMER: `${localURL}/customer/update`,
  UPLOAD_REFUND: `${localURL}/bonus/upload`,
  BONUS_SEARCH: `${localURL}/bonus/search`,
  UPLOAD_REFUND_CUSTOMERS_ACCEPT: `${localURL}/bonus/refund_upload`,
  UPLOAD_BONUS_SOCIAL: `${localURL}/bonus/social_upload`,
  REGISTER_OTHER_AGENT_CUSTOMER: `${localURL}/customer/add_agent`,
  DELETE_CUSTOMER: `${localURL}/customer/delete`,
  RESET_CUSTOMER_PASSWORD: `${localURL}/customer/reset_password`,
  TURNOVER_CUSTOMER: `${localURL}/customer/check_turnover`,

  BANK_SEARCH: `${localURL}/banking/search`,
  BANK_SEARCH_TYPE: `${localURL}/banking/search_type`,
  BANK_CREATE: `${localURL}/banking/create`,
  BANK_UPDATE: `${localURL}/banking/update`,
  BANK_DELETE: `${localURL}/banking/delete`,
  BANK_TIER_SEARCH: `${localURL}/banking/search_tier`,
  BANK_TIER_UPDATE: `${localURL}/banking/update_tier`,
  BANK_GENERATE_OTP: `${localURL}/banking/generate_otp`,
  BANK_CONFIRM_OTP: `${localURL}/banking/confirm_otp`,
  BANK_TESTING: `${localURL}/banking/test`,
  BANK_LIST_MANUAL_LOGIN: `${localURL}/banking/list_manual_login`,
  BANK_SUBMIT_MANUAL_LOGIN: `${localURL}/banking/submit_manual_login`,
  GET_WITHDRAW_SETTING: `${localURL}/banking/auto_withdraw_config`,
  UPDATE_WITHDRAW_SETTING: `${localURL}/banking/update_auto_withdraw_config`,
  GET_UPDATE_WITHDRAW_TIME_CONFIG: `${localURL}/banking/prevent_withdraw_config`,
  UPDATE_WITHDRAW_TIME_SETTING: `${localURL}/banking/update_prevent_withdraw_config`,

  SEARCH_CC_USER: `${localURL}/user/search`,
  CREATE_CC_USER: `${localURL}/user/register`,
  UPDATE_CC_USER: `${localURL}/user/update`,
  DELETE_CC_USER: `${localURL}/user/delete`,
  RESET_TWO_FACTOR: `${localURL}/user/remove_authenticator`,

  WORK_ORDER_SEARCH: `${localURL}/work_order/search`,
  WORK_ORDER_SEARCH_TYPE: `${localURL}/work_order/search_type`,
  WORK_ORDER_SEARCH_INCOMPLETE: `${localURL}/work_order/search_incomplete`,
  WORK_ORDER_CREATE: `${localURL}/work_order/create`,
  WORK_ORDER_UPDATE: `${localURL}/work_order/update`,
  WORK_ORDER_UPDATE_PGADMIN: `${localURL}/work_order/update_status_pgadmin`,
  WORK_ORDER_UPDATE_STATUS: `${localURL}/work_order/update_status`,
  WORK_ORDER_UPDATE_NO_RETRY: `${localURL}/work_order/update_no_retry`,
  WORK_ORDER_DELETE: `${localURL}/work_order/delete`,
  WORK_ORDER_RETRY: `${localURL}/work_order/retry`,
  WORK_ORDER_FORCE_SUCCESS: `${localURL}/work_order/manual_success`,
  WORK_ORDER_APPROVE: `${localURL}/work_order/approve`,
  WORK_ORDER_APPROVE_MANUAL: `${localURL}/work_order/approve_manual`,
  WORK_ORDER_REJECT: `${localURL}/work_order/reject`,
  WORK_ORDER_WITHDRAW_IMAGE: `${localURL}/work_order/withdraw_image`,
  APPROVE_DEPOSIT_WORKORDER: `${localURL}/work_order/approve_deposit`,
  WORK_ORDER_MANUAL_MAPPING: `${localURL}/work_order/manual_mapping`,
  WORK_ORDER_FORCE_MAPPING: `${localURL}/work_order/force_mapping_success`,
  WORK_ORDER_MANUAL_CREDIT_SUCCESS: `${localURL}/work_order/manual_credit_success`,
  CUSTOMER_HISTORY: `${localURL}/work_order/customer_history`,
  BONUS_SOCIAL_SEARCH: `${localURL}/bonus/social_refund_search`,
  BONUS_SOCIAL_RETRY: `${localURL}/bonus/social_refund_retry`,
  WORK_ORDER_CREATE_DEMO: `${localURL}/work_order/create_demo`,

  DASHBOARD_WITHDRAW_STATS: `${localURL}/dashboard/deposit_withdraw_stats`,
  DASHBOARD_WORK_ORDER: `${localURL}/dashboard/work_orders`,
  DASHBOARD_ACTIVE_USERS: `${localURL}/dashboard/active_users`,
  DASHBOARD_NEW_USERS: `${localURL}/dashboard/new_users`,
  DASHBOARD_BONUS_LOST_STATS: `${localURL}/dashboard/bonus_lost_stats`,
  DASHBOARD_CUSTOMER_STATS: `${localURL}/dashboard/customer_deposit_withdraw_stats`,
  DASHBOARD_CUSTOMER_NO_DEPOSIT: `${localURL}/dashboard/customer_no_deposit`,
  DASHBOARD_AGENTS_SUMMARY: `${localURL}/dashboard/agents_summary`,
  DASHBOARD_AGENTS_WORKORDERS: `${localURL}/dashboard/agents_work_orders`,
  DASHBOARD_CUSTOMER_CHANNEL: `${localURL}/dashboard/customer_channel`,
  DASHBOARD_CUSTOMER_MONEY_SITE: `${localURL}/dashboard/customer_money_site`,

  SEARCH_BLACK_LIST: `${localURL}/blacklist/search`,
  BLACK_LIST: `${localURL}/blacklist/blacklist`,
  UN_BLACK_LIST: `${localURL}/blacklist/unblacklist`,

  SMS_READER: `${localURL}/banking/get_sms_v1`,

  SEARCH_AGENTS: `${localURL}/agent/search`,
  UPDATE_AGENTS: `${localURL}/agent/update`,

  NEWS_CREATE: `${localURL}/news/create`,
  NEWS_SEARCH: `${localURL}/news/search`,
  NEWS_UPDATE: `${localURL}/news/update`,
  NEWS_DELETE: `${localURL}/news/delete`,

  DASHBOARD_WARNING: `${localURL}/dashboard/warnings`,

  SEARCH_PROSPECT: `${localURL}/prospect/search`,
  UPDATE_PROSPECT: `${localURL}/prospect/update`,
  LOCK_UNLOCK_CUSTOMER: `${localURL}/customer/lock_unlock_customer`,
  INQUIRY_CUSTOMER_LOG: `${localURL}/customer/logs`,
  LIST_AGENTS: `${localURL}/customer/list_agents`,

  INVOICE: `${localURL}/invoice/summary`,
  INVOICE_GET_ACCOUNT: `${localURL}/invoice/bank_account`,
  INVOICE_UPDATE_ACCOUNT: `${localURL}/invoice/update_bank_account`,
  INVOICE_GET_SLIP: `${localURL}/invoice/slip`,
  INVOICE_UPLOAD_SLIP: `${localURL}/invoice/upload_slip`,
  INVOICE_MARK_PAID: `${localURL}/invoice/mark_paid`,

  SEARCH_LUCKYDRAW: `${localURL}/luckydraw/search`,
  CREATE_LUCKYDRAW: `${localURL}/luckydraw/create`,
  UPDATE_LUCKYDRAW: `${localURL}/luckydraw/update`,
  DELETE_LUCKYDRAW: `${localURL}/luckydraw/delete`,
  SEARCH_LUCKYDRAW_STATS: `${localURL}/luckydraw/search_stats`,

  INVOICE_GET_ALL_SLIP: `${localURL}/invoice/slip_all`,
  INVOICE_SUMMARY_HISTORY: `${localURL}/invoice/summary_history`,

  SEARCH_ABNORMAL_STATEMENT: `${localURL}/abnormal_statement/search`,
  UPDATE_ABNORMAL_STATEMENT: `${localURL}/abnormal_statement/update`,

  AGENT_LIST_GCLUB: `${localURL}/agent/list_gclub`,
  ADD_CREDIT_GCLUB: `${localURL}/agent/topup_gclub`,
  ADD_CREDIT_GCLUB_HISTORY: `${localURL}/agent/topup_gclub_history`,
  BANK_STATEMENTS: `${localURL}/banking/statements`,
  BANK_STATEMENTS_DETAILS: `${localURL}/banking/statement_detail`,
  BANK_VIEW_PASSWORD: `${localURL}/banking/view_password`,
  BANK_VIEW_PASSWORD_LOGS: `${localURL}/banking/view_password_logs`,
  BANK_STATEMENTS_ERROR: `${localURL}/banking/statements_error`,

  STATEMENT_DELAY_CONFIG: `${localURL}/banking/statement_delay_config`,
  UPDATE_STATEMENT_DELAY_CONFIG: `${localURL}/banking/update_statement_delay_config`,
  QR_TRANSFER_CONFIG: `${localURL}/banking/qr_transfer_config`,
  BANK_TRANSFER_CONFIG: `${localURL}/banking/bank_transfer_config`,
  UPDATE_QR_TRANSFER_CONFIG: `${localURL}/banking/update_qr_transfer_config`,
  UPDATE_BANK_TRANSFER_CONFIG: `${localURL}/banking/update_bank_transfer_config`,
  VIEW_BANK_FACE_IMAGES: `${localURL}/banking/view_face_images`,
  BANK_UPDATE_STATUS: `${localURL}/banking/update_status`,
  SETTLEMENT_SEARCH:`${localURL}/banking/statement_search`,
  READ_STATEMENT:   `${localURL}/banking/read_statement`,
  GET_VIZPAY_CONFIG:   `${localURL}/banking/prevent_deposit_vizpay_config`,
  UPDATE_VIZPAY_CONFIG: `${localURL}/banking/update_prevent_deposit_vizpay_config`,
  GET_ACCOUNT_CONFIG:   `${localURL}/banking/account_config`,
  UPDATE_ACCOUNT_CONFIG: `${localURL}/banking/update_account_config`,
  VALIDATE_VIZ_KEYS: `${localURL}/banking/check_vizpay_keys`,
  GET_COREPAY_CONFIG:   `${localURL}/banking/corepay_transfer_config`,
  UPDATE_COREPAY_CONFIG:   `${localURL}/banking/update_corepay_transfer_config`,
  GET_HENGPAY_CONFIG:   `${localURL}/banking/hengpay_transfer_config`,
  UPDATE_HENGPAY_CONFIG:   `${localURL}/banking/update_hengpay_transfer_config`,
  UPDATE_AUTO_SWEEP:   `${localURL}/banking/auto_sweep_update`,
  DELETE_AUTO_SWEEP:   `${localURL}/banking/auto_sweep_delete`,
  GET_PERFECTPAYS_CONFIG:   `${localURL}/banking/perfectpays_transfer_config`,
  UPDATE_PERFECTPAYS_CONFIG:   `${localURL}/banking/update_perfectpays_transfer_config`,
  GET_PAYONEX_CONFIG:   `${localURL}/banking/payonex_transfer_config`,
  UPDATE_PAYONEX_CONFIG:   `${localURL}/banking/update_payonex_transfer_config`,
  GET_WEALTHWAVE_CONFIG:   `${localURL}/banking/wealthwave_transfer_config`,
  UPDATE_WEALTHWAVE_CONFIG:   `${localURL}/banking/update_wealthwave_transfer_config`,
  GET_MINIMUM_WITHDRAW_AMOUNT:   `${localURL}/banking/minimum_withdraw_amount`,
  UPDATE_MINIMUM_WITHDRAW_AMOUNT:   `${localURL}/banking/update_minimum_withdraw_amount`,
  GET_GATEWAY_DEPOSIT_AMOUNT:   `${localURL}/banking/gateway_deposit_amount`,
  UPDATE_GATEWAY_DEPOSIT_AMOUNT:   `${localURL}/banking/update_gateway_deposit_amount`,

  SEARCH_CONFIG: `${localURL}/config/search`,
  UPDATE_CONFIG: `${localURL}/config/update`,
  GET_KPLUS_GEN_QR: `${localURL}/config/kplusgen`,

  UNLOCK_AGENT_GCLUB: `${localURL}/agent/unlock_gclub_agent`,

  CC_WORK_ORDERS: `${localURL}/dashboard/cc_work_orders`,

  AFFILIATE_MEMBER_LIST: `${localURL}/affiliate/member_list`,
  AFFILIATE_SEARCH: `${localURL}/affiliate/search`,
  AFFILIATE_SUMMARY: `${localURL}/affiliate/summary`,
  DASHBOARD_BONUS_AFFILIATE_STATS: `${localURL}/dashboard/bonus_affiliate_stats`,
  DASHBOARD_GATEWAY_STATS: `${localURL}/dashboard/gateway_stats`,

  ACTIVE_AGENT: `${localURL}/agent/active`,
  CREATE_TRANSFER: `${localURL}/work_order/transfer_create`,
  SEARCH_TRANSFER:`${localURL}/work_order/transfer_search`,
  SEARCH_GATEWAY_QR:`${localURL}/work_order/gateway_search`,

  SEARCH_NEW_CUSTOMER_STATS: `${localURL}/bonus/new_customer/search_stats`,
  SEARCH_NEW_CUSTOMER_BONUS: `${localURL}/bonus/new_customer/search`,
  CREATE_NEW_CUSTOMER_BONUS: `${localURL}/bonus/new_customer/create`,
  UPDATE_NEW_CUSTOMER_BONUS: `${localURL}/bonus/new_customer/update`,
  DELETE_NEW_CUSTOMER_BONUS: `${localURL}/bonus/new_customer/delete`,
  GET_LINK_NEW_CUSTOMER_BONUS: `${localURL}/bonus/new_customer/link`,
}