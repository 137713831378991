import {
    Button,
    Card,
    Col,
    Form,
    FormControl,
    InputGroup, Modal,
    Table
} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import api_workorder from "../../api/workorder";
import {useGlobalStore} from "../../store/globalContext";
import moment from "moment/moment";
import NewCustomerBonusHistory from "./NewCustomerBonusHistory";

const TableNewCustomerBonus = (props)=>{
    const [, dispatch] = useGlobalStore();
    const prevTriggerRef = useRef("");
    const {trigger} = props

    const {
        register: registerSearchNewCustomerPromotion,
        handleSubmit: handleSubmitSearchNewCustomerPromotion,
        reset:resetSearchNewCustomerPromotion,
        errors: errorSearchNewCustomerPromotion
    } = useForm({
    })

    const {
        register: registerGenerateLink,
        handleSubmit: handleSubmitGenerateLink,
        reset:resetGenerateLink,
        errors: errorGenerateLink,
        setValue: setValueGenerateLink,
        getValues: getValueGenerateLink
    } = useForm({
        defaultValues:{
            register_link:""
        }
    })

    const [newCustomerBonusPromotionList, setNewCustomerBonusPromotionList] = useState([])
    const [enableModal, setEnableModal] = useState(false)
    const [contentModal, setContentModal] = useState()


    const toggleActiveNewCustomerPromotion = async (promotionData, checked) =>{

        // console.log(promotionData)
        // console.log(checked)

        const request = {
            id: promotionData.id,
            name: promotionData.name,
            description: promotionData.description,
            active: checked,
            amount: promotionData.amount,
            amount_limit: promotionData.amount_limit,
            message: promotionData.message,
        }

        try {
            const response = await api_workorder.UPDATE_NEW_CUSTOMER_BONUS(request)
            const { data } = response.data
            // console.log(`update new customer bonus promotion data: `, data);
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `${checked === true?"เปิด":"ปิด"} โปรโมชั่นใหม่แจกลูกค้า`,
                    message: `${checked === true?"เปิด":"ปิด"}โปรโมชั่นใหม่แจกลูกค้าสำเร็จ`,
                }
            } );
            void searchNewCustomerBonusPromotion("","")
        } catch (e) {
            console.log(`update new customer bonus promotion error:`, e.message);
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `${checked === true?"เปิด":"ปิด"} โปรโมชั่นใหม่แจกลูกค้าไม่สำเร็จ`,
                    message: `${checked === true?"เปิด":"ปิด"}โปรโมชั่นใหม่แจกลูกค้าไม่สำเร็จ ${e.message}`,
                }
            } );
            resetSearchNewCustomerPromotion({})
        }
    }

    const submitSearchNewCustomerPromotion =(data)=>{
        void searchNewCustomerBonusPromotion(data.id, data.keyword)
    }
    const searchNewCustomerBonusPromotion = async (id, keyword) =>{
        try {
            const response = await api_workorder.SEARCH_NEW_CUSTOMER_BONUS({
                id:id,
                keyword: keyword
            })
            const { data } = response.data
            // console.log(`search new customer bonus promotion data: `, data.filter((d)=> d.deleted !== true));
            setNewCustomerBonusPromotionList(data.filter((d)=> d.deleted !== true))
        } catch (e) {
            console.log(`search new customer bonus promotion error:`, e.message);
        }
    }

    const resetNewSearchCustomerTable =()=>{
        resetSearchNewCustomerPromotion({})
        void searchNewCustomerBonusPromotion("","")
    }

    const deleteNewPromotionForNewCustomer = async (id)=>{
        try{
            const response = await api_workorder.DELETE_NEW_CUSTOMER_BONUS({
                id:id,
            })
            const { data } = response.data
            // console.log(`search new customer bonus promotion data: `, data);
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `ลบโปรโมชั่นใหม่แจกลูกค้า`,
                    message: `ลบโปรโมชั่นใหม่แจกลูกค้า ID ${id} สำเร็จ`,
                }
            } );
            void searchNewCustomerBonusPromotion("","")
        }catch (error){
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ลบโปรโมชั่นใหม่แจกลูกค้า`,
                    message: `ลบโปรโมชั่นใหม่แจกลูกค้า ID ${id} ไม่สำเร็จ ${error.message}`,
                }
            } );
            resetNewSearchCustomerTable({})
        }
    }

    const generateNewPromotionForNewCustomerLink = async (formData)=>{
        // console.log("formData: ", formData)
        try {
            const response = await api_workorder.GET_LINK_NEW_CUSTOMER_BONUS({
                id:formData.id,
                url: formData.current_link
            })
            const { data } = response.data
            // console.log(`generate new customer bonus promotion link data: `, data);
            setContentModal(
                <>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>ลิงค์สมัครโปรโมชั่นลูกค้า</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>ลิงค์สมัครพร้อมรับโปร</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="input"
                                readOnly
                                name="register_link"
                                defaultValue={""}
                                ref={registerGenerateLink}
                            />
                            <InputGroup.Prepend className={"flex justify-content-center"}>
                                <Button><i className={"fas fa-copy"} onClick={()=>copyRegisterNewCustomerPromotionLink(getValueGenerateLink("register_link"))}/></Button>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Modal.Body>
                </>
            )
            setEnableModal(true)
            setValueGenerateLink("register_link", data.url)
            // console.log(getValueGenerateLink("register_link"))
            resetGenerateLink({})
        } catch (e) {
            console.log(`generate new customer bonus promotion link  error:`, e.message);
        }
    }

    const copyRegisterNewCustomerPromotionID = async (id)=>{
        try{
            // await navigator.clipboard.writeText(id)
            setValueGenerateLink("id", id)
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `คัดลอก ID โปรโมชั่นใหม่แจกลูกค้า`,
                    message: `คัดลอก ID ${id} สำเร็จ`,
                }
            } );
        }catch (error){
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `คัดลอก ID โปรโมชั่นใหม่แจกลูกค้า`,
                    message: `คัดลอก ID ${id} ไม่สำเร็จ ${error.message}`,
                }
            } );
        }

    }

    const copyRegisterNewCustomerPromotionLink = async (link)=>{
        try{
            await navigator.clipboard.writeText(link)
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `คัดลอก ID โปรโมชั่นใหม่แจกลูกค้า`,
                    message: `คัดลอก LINK สมัครสำเร็จ`,
                }
            } );
        }catch (error){
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `คัดลอก ID โปรโมชั่นใหม่แจกลูกค้า`,
                    message: `คัดลอก LINK สมัครไม่สำเร็จ ${error.message}`,
                }
            } );
        }

    }

    const showPreviousLink = (links)=>{
        setContentModal(
            <>
                <Modal.Header closeButton={true}>
                    <Modal.Title>ลิงค์ที่เคยสร้างไปแล้ว</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                        <tr className={"text-center"}>
                            <th>#</th>
                            {/*<th>ID</th>*/}
                            <th>โปรโมชั่น ID</th>
                            <th>ลิงค์โปรโมชั่น</th>
                            <th>สร้างโดย</th>
                            <th>สร้างเมื่อ</th>
                            <th>อัพเดทล่าสุดโดย</th>
                            <th>อัพเดทล่าสุดเมื่อ</th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            links?.map((data, index)=>{
                                return (
                                    <tr className={`text-center`} key={data.id}>
                                        <td>
                                            {index+1}
                                        </td>
                                        <td>{data.promotion_id}</td>
                                        <td className={"text-bold"}>
                                            {
                                                <Button size={"xs"} variant={"outline-primary"} onClick={() => copyRegisterNewCustomerPromotionLink(data.link)}>
                                                    {data.link}
                                                </Button>
                                            }
                                        </td>
                                        <td>{data.created_by}</td>
                                        <td>{moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                        <td>{data.updated_by}</td>
                                        <td>{moment(data.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </Modal.Body>
            </>
        )
        setEnableModal(true)
    }

    useEffect(() => {
        void searchNewCustomerBonusPromotion("", "")
    }, []);

    useEffect(() => {
        // console.log("trigger: ", trigger)
        if (trigger !== '' && trigger !== prevTriggerRef.current) {
            void searchNewCustomerBonusPromotion("", "")
            prevTriggerRef.current = trigger
        }

    },[trigger])

    return (
        <>
        <Form key={"searchNewCustomerBonusPromotionForm"} noValidate onSubmit={handleSubmitSearchNewCustomerPromotion(submitSearchNewCustomerPromotion)}>
            <Form.Row className="col-md-12 pt-4">
                <Form.Group as={Col} md="4" lg="4" controlId="validation22">
                    <Form.Label>ค้นหาโปรโมชั่นลูกค้าใหม่</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>ค้นหาด้วย ID</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            type="input"
                            name="id"
                            placeholder="66fad0d6cca0xxxxx"
                            defaultValue={""}
                            ref={registerSearchNewCustomerPromotion()}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="8" lg="8" controlId="validation23">
                    <Form.Label className={"text-white"}>-</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>ค้นหาด้วยชื่อหรือรายละเอียด</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            type="input"
                            name="keyword"
                            placeholder="66fad0d6cca0xxxxx"
                            defaultValue={""}
                            ref={registerSearchNewCustomerPromotion()}
                        />
                    </InputGroup>
                </Form.Group>
            </Form.Row>
            <Form.Row className={"pl-2"}>
                <Form.Group>
                    <Button type={"submit"} variant={"primary"}>ค้นหา</Button>{' '}
                    <Button type={"button"} variant={"danger"} onClick={()=>resetNewSearchCustomerTable()}>ล้างค่า</Button>
                </Form.Group>
            </Form.Row>
        </Form>
            <Form key={"generateNewCustomerPromotionLink"} noValidate onSubmit={handleSubmitGenerateLink(generateNewPromotionForNewCustomerLink)}>
                <Modal show={enableModal} size={"xl"} onHide={()=>setEnableModal(false)}>
                    {contentModal}
                </Modal>
                <Form.Row className="col-md-12 pt-4">
                    <Form.Group as={Col} md="6" lg="6" controlId="validation23">
                        <Form.Label>สร้างลิงค์โปรโมชั่นลูกค้าใหม่</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>ลิงค์สมัครลูกค้าปัจจุบันที่ต้องการทำโปรโมชั่น</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="input"
                                name="current_link"
                                placeholder="https://customer.xxxxxxxxxxx.com/resigter?agent=xxxxx"
                                required
                                defaultValue={""}
                                ref={registerGenerateLink({required:true})}
                                isInvalid={!!errorGenerateLink.current_link}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                กรุณาระบุลิงค์สมัครที่ต้องการ
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="5" lg="5" controlId="validation23">
                        <Form.Label className={"text-white"}>-</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>ID โปรโมชั่น</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="input"
                                name="id"
                                placeholder="66fad0d6cca0xxxxx"
                                required
                                defaultValue={""}
                                ref={registerGenerateLink({required:true})}
                                isInvalid={!!errorGenerateLink.id}
                            />
                            <InputGroup.Append><Button type={"submit"}>สร้างลิงค์</Button></InputGroup.Append>
                            <Form.Control.Feedback  type="invalid" tooltip>
                                กรุณาเพิ่มชื่อโปรโมชั่น
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Form.Row>
            </Form>
            <Table>
                <thead>
                <tr className={"text-center"}>
                    <th>#</th>
                    <th>ID</th>
                    <th>ชื่อโปรโมชั่น</th>
                    <th>รายละเอียดโปรโมชั่น</th>
                    <th className={"text-nowrap"}>จำนวนเงินที่แจกลูกค้าใหม่</th>
                    <th className={"text-nowrap"}>จำนวนเงินแจกรวมทั้งหมดของโปรโมชั่นนี้</th>
                    <th>ข้อความแจ้งเตือนกรณีครบโควต้า</th>
                    <th>สร้างเมื่อ</th>
                    <th>สร้างโดย</th>
                    <th>อื่นๆ</th>
                </tr>
                </thead>
                <tbody>

                    {
                        newCustomerBonusPromotionList?.map((data, index)=>{
                            return (
                                <tr className={`text-center ${getValueGenerateLink("id") === data.id ? "text-bold text-green" : ""}`}
                                    key={data.id}>
                                    <td>
                                        <Form.Switch custom size={"sm"}
                                                     id={`promotion_active.${index}.active`}
                                                     name={`promotion_active.${index}.active`}
                                                     defaultChecked={data.active === true}
                                                     defaultValue={data.id}
                                                     onChange={(e) => toggleActiveNewCustomerPromotion(data, e.target.checked)}
                                        />
                                    </td>
                                    <td>
                                        {
                                            data.active === true ? <Button size={"xs"} variant={"outline-primary"}
                                                                           onClick={() => copyRegisterNewCustomerPromotionID(data.id)}>
                                                {data.id}
                                            </Button> : <>-</>
                                        }
                                    </td>
                                    <td>{data.name}</td>
                                    <td className={"text-sm"}>{data.description}</td>
                                    <td>{data.amount}</td>
                                    <td>{data.amount_limit}</td>
                                    <td className={"text-sm"}>{data.message}</td>
                                    <td>{moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td>{data.created_by}</td>
                                    <td>
                                        <Form.Group className={"flex"}>
                                            <div>
                                                <Button size={"sm"} variant={"primary"}
                                                        onClick={() => showPreviousLink(data.links)}
                                                        disabled={!data.links}><i
                                                    className={"fas fa-history"}/></Button>
                                            </div>
                                            <div className={"pl-1"}>
                                                <Button size={"sm"} variant={"danger"}
                                                        onClick={() => deleteNewPromotionForNewCustomer(data.id)}><i
                                                    className={"fas fa-trash"}/></Button>
                                            </div>
                                        </Form.Group>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </>
    )
}


export default function NewCustomerBonus() {
    const [, dispatch] = useGlobalStore();
    const {
        register: registerNewCustomerBonusForm,
        handleSubmit: handleSubmitNewCustomerBonus,
        errors: errorNewCustomerBonus,
        reset:resetNewCustomerBonus,
        setValue: setValueNewCustomerBonus,
        getValues: getValuesNewCustomerBonus,
        watch: watchNewCustomerBonus
    } = useForm({defaultValues:{
        trigger: ""
        }, shouldUnregister:false})

    const watchTrigger = watchNewCustomerBonus("trigger")
    const onSubmitNewCustomerBonus = async (createNewCustomerBonusData) =>{
        // console.log("submit onSubmitNewCustomerBonus: ", createNewCustomerBonusData)

        try {
            const response = await api_workorder.CREATE_NEW_CUSTOMER_BONUS( createNewCustomerBonusData )
            const { data } = response.data
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'success',
                    title: `สร้างโปรโมชั่นใหม่แจกลูกค้า`,
                    message: `สร้างโปรโมชั่นใหม่แจกลูกค้า ${data} สำเร็จ`,
                }
            } );
            resetNewCustomerBonus({})
            setValueNewCustomerBonus("trigger", moment(new Date()).format("HH:mm:ss").toString())
        } catch (e) {
            console.log(`submit create new customer bonus failed`, e.message);
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `สร้างโปรโมชั่นใหม่แจกลูกค้า`,
                    message: `สร้างโปรโมชั่นใหม่แจกลูกค้าไม่สำเร็จ ${e.message}`,
                }
            } );
        }
    }

    return(
        <Card className="card-dark">
            <Card.Header>จัดการโบนัสลูกค้าใหม่</Card.Header>
            <Card.Body>
                <Form key={2} noValidate onSubmit={handleSubmitNewCustomerBonus(onSubmitNewCustomerBonus)}>
                    <Form.Row className="col-md-12">
                        <Form.Group as={Col} md="6" lg="6" controlId="validation02">
                            <Form.Label>เพิ่มโปรโมชั่น Bonus ลูกค้าใหม่</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>ชื่อโปรโมชั่น</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    type="input"
                                    name="name"
                                    placeholder="สมัครวันนี้รับทันที X บาท"
                                    required
                                    defaultValue={""}
                                    ref={registerNewCustomerBonusForm({ required: true})}
                                    isInvalid={!!errorNewCustomerBonus.name}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    กรุณาเพิ่มชื่อโปรโมชั่น
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" lg="6" className="col-md-12">
                            <Form.Label className={"text-white"}>-</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>รายละเอียดโปรโมชั่น</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="input"
                                              name="description"
                                              placeholder="สมัครวันนี้รับทันที xxxx บาท"
                                              defaultValue={""}
                                              ref={registerNewCustomerBonusForm}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="col-md-12">
                        <Form.Group as={Col} md="3" lg="3">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>จำนวนเงินแจก</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="number"
                                              required
                                              name="amount"
                                              defaultValue={1}
                                              ref={registerNewCustomerBonusForm({ required: true})}
                                              isInvalid={!!errorNewCustomerBonus.amount}
                                />

                                <Form.Control.Feedback type="invalid" tooltip>
                                    กรุณาระบุจำนวนเงินแจกลูกค้าใหม่
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>จำนวนแจกรวม(ไม่เกิน)</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="number"
                                              required
                                              name="amount_limit"
                                              defaultValue={10}
                                              ref={registerNewCustomerBonusForm({ required: true})}
                                              isInvalid={!!errorNewCustomerBonus.amount_limit}
                                />

                                <Form.Control.Feedback type="invalid" tooltip>
                                    กรุณาระบุจำนวนเงินลิมิตแจกลูกค้าใหม่(แจกไม่เกิน)
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="col-md-12">
                        <Form.Group as={Col} md="12" lg="12">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>ข้อความแจ้งเตือนกรณีโปรโมชั่นครบแล้ว</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="textarea"
                                              rows={3}
                                              name="message"
                                              required
                                              placeholder="ลูกค้าได้รับโปรโมชั่นครบแล้ว"
                                              defaultValue={""}
                                              ref={registerNewCustomerBonusForm({ required: true})}
                                              isInvalid={!!errorNewCustomerBonus.message}
                                />

                                <Form.Control.Feedback type="invalid" tooltip>
                                    กรุณาระบุข้อความแจ้งลูกค้า(กรณีแจกครบแล้ว)
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="col-md-12">
                        <Button type={"submit"} variant={"dark"}>
                            เพิ่มโปรโมชั่น
                        </Button>
                    </Form.Row>
                </Form>
                <TableNewCustomerBonus trigger={watchTrigger}/>
                <NewCustomerBonusHistory/>
            </Card.Body>
        </Card>
    )
}