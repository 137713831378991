import {Button, Col, Form} from "react-bootstrap";
import LuckyDrawOptions from "./LuckyDrawOptions";
import React, {useEffect} from "react";

export default function LuckyDrawForm ( props ) {
    const { register, errors, control, watch, setValue} = props;
    const periodOptions = [
        { innerText: "รายวัน", value: "daily" },
        { innerText: "รายสัปดาห์", value: "weekly" },
        { innerText: "รายเดือน", value: "monthly" },
        { innerText: "ทั้งหมด", value: "campaign" }
    ];

    const watchPeriodType = watch("period_type")


    useEffect(()=>{
        // console.log("watchPeriodType: ", watchPeriodType)
    },[watchPeriodType])

    return (
        <>
            <Form.Row>
                <Form.Group as={Col} md="3" lg="3" controlId="validation01">
                    <Form.Label>ชื่อเกมชิงโชค</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="name"
                        placeholder={"Lucky-Draw 101"}
                        isInvalid={!!errors.name}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาใส่ชื่อเกมชิงโชค
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation02">
                    <Form.Label>คำอธิบายเกมชิงโชค</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="description"
                        placeholder={"สุ่ม Lucky-Draw วันนี้ ลุ้นเครดิตฟรีสุงสุดถึง 100,000 บาท!"}
                        isInvalid={!!errors.description}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาใส่คำอธิบายเกมชิงโชค
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation06">
                    <Form.Label>เลือกรูปแบบการแจก luckydraw</Form.Label>
                    <Form.Control as="select"
                                  required
                                  name="period_type"
                                  defaultValue=""
                                  isInvalid={!!errors.period_type}
                                  custom
                                  ref={register( { required: true } )}
                    >
                        <option value="">Please Select</option>
                        {
                            periodOptions.map( ( { innerText, value }, index ) =>
                                <option
                                    key={index}
                                    value={value}
                                >
                                    {innerText}
                                </option>
                            )
                        }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกรูปแบบการแจก Lucky Draw
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation06">
                    <Form.Label>แจกซ้ำกรณีฝากตามโควต้า</Form.Label>
                    <Form.Control as="select"
                                  required
                                  name="repeat"
                                  defaultValue={false}
                                  isInvalid={!!errors.repeat}
                                  disabled={watchPeriodType !== "daily"}
                                  custom
                                  ref={register({required: true,
                                      setValueAs: v => JSON.parse(v)})
                                }
                    >
                        <option value={false}>ไม่แจกซ้ำ</option>
                        <option value={true}>แจกซ้ำได้</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาเลือกรูปแบบการแจก Lucky Draw
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" lg="3" controlId="validation03">
                    <Form.Label>ลิงค์รูปภาพเกมชิงโชค</Form.Label>
                    {
                        watch("image_url") &&
                        <Button size={"xs"} className={"ml-2"}
                                onClick={()=>window.open(watch("image_url"), '_blank').focus()}>
                            <i className="fas fa-external-link-alt"/>
                        </Button>
                    }
                    <Form.Control
                        required
                        type="text"
                        name="image_url"
                        // placeholder={"https://d22eqwgf5ils9u.cloudfront.net/mcity.jpg"}
                        isInvalid={!!errors.image_url}
                        ref={register({required: true})}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาใส่รูปภาพเกมชิงโชค
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation07">
                    <Form.Label>รูปภาพแสดงลูกค้าได้รางวัล</Form.Label>
                    {
                        watch("win_image_url") &&
                        <Button size={"xs"} className={"ml-2"}
                                onClick={()=>window.open(watch("win_image_url"), '_blank').focus()}>
                            <i className="fas fa-external-link-alt"/>
                        </Button>
                    }
                    <Form.Control
                        required
                        type="text"
                        name="win_image_url"
                        // placeholder={"https://d22eqwgf5ils9u.cloudfront.net/win_image_url.jpg"}
                        isInvalid={!!errors.win_image_url}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาใส่ลิงค์รูปภาพเมื่อลูกค้าได้รางวัล
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation08">
                    <Form.Label>รูปภาพแสดงลูกค้าพลาดรางวัล</Form.Label>
                    {
                        watch("lose_image_url") &&
                        <Button size={"xs"} className={"ml-2"}
                                onClick={()=>window.open(watch("lose_image_url"), '_blank').focus()}>
                            <i className="fas fa-external-link-alt"/>
                        </Button>
                    }
                    <Form.Control
                        required
                        type="text"
                        name="lose_image_url"
                        // placeholder={"https://d22eqwgf5ils9u.cloudfront.net/lose_image_url.jpg"}
                        isInvalid={!!errors.lose_image_url}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาใส่ลิงค์รูปภาพเมื่อรูปภาพแสดงลูกค้าพลาดรางวัล
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation09">
                    <Form.Label>สีขอบกงล้อและจุดกลาง</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="border_dot_color"
                        placeholder={"#fff144"}
                        isInvalid={!!errors.border_dot_color}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาระบุสีขอบกงล้อและจุดกลาง
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" lg="3" controlId="validation10">
                <Form.Label>วันที่เริ่มต้น</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        name="start_date"
                        placeholder={"2025-01-01"}
                        isInvalid={!!errors.start_date}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาระบุวันที่เริ่มต้นเกม
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation10">
                    <Form.Label>วันที่สิ้นสุด</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        name="end_date"
                        placeholder={"2025-12-01"}
                        isInvalid={!!errors.end_date}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาระบุวันที่สิ้นสุดเกม
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3" controlId="validation11">
                    <Form.Label>โอกาสได้รางวัล(รูปแบบ%)</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        name="chance_rate"
                        placeholder={"60"}
                        isInvalid={!!errors.chance_rate}
                        ref={register( { required: true } )}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                        กรุณาระบุโอกาสได้รางวัลในรูปแบบเปอร์เซนต์
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <LuckyDrawOptions register={register} control={control} watch={watch} errors={errors}/>
        </>
    )

}