import {Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import api_workorder from "../../api/workorder";
import {useGlobalStore} from "../../store/globalContext";
import {useForm} from "react-hook-form";
import moment from "moment";

export default function NewCustomerBonusHistory(){
    const dispatch = useGlobalStore()[1];
    const [newCustomerPromotionList, setNewCustomerPromotionList] = useState([])
    const [newCustomerBonusHistoryList, setNewCustomerBonusHistoryList] = useState([])

    const {
        register:registerNewCustomerPromotionList,
        getValues:getValuesNewCustomerPromotionList,
        setValue:setValuesNewCustomerPromotionList,
        errors: errorsNewCustomerPromotionList,
        handleSubmit: handleSubmitNewCustomerPromotionList
        } = useForm({
        defaultValues:{
            new_customer_promotion:"",
            total_customers: 0,
            customer_list:[],
            total_amount:0
        }
    })
    const getNewCustomerPromotionList = async ()=>{
        try{
            const response = await api_workorder.SEARCH_NEW_CUSTOMER_BONUS({id:"", keyword:""})
            const { data } = response.data
            // console.log(`get new customer bonus promotion list data: `, data);
            setNewCustomerPromotionList(data)
        }catch (error){
            console.log("get new customer bonus promotion list error: ", error.message)
        }
    }

    const submitSearchNewCustomerBonusHistory = async (formData) =>{
        // console.log("submitSearchNewCustomerBonusHistory formData: ", formData)

        try{
            const response = await api_workorder.SEARCH_NEW_CUSTOMER_STATS({
                id: formData.new_customer_promotion
            })
            const { data } = response.data
            // console.log(`get new customer stats list data: `, data);
            setValuesNewCustomerPromotionList("total_amount", data.total_amount)
            setValuesNewCustomerPromotionList("total_customers", data.total_customers)
            setNewCustomerBonusHistoryList(data.customer_list)
        }catch (e){

            // console.log("submitSearchNewCustomerBonusHistory formData: ", formData)
            dispatch( {
                type: "TOAST2",
                value: {
                    show: true,
                    type: 'danger',
                    title: `ค้นหาประวัติโปรโมชั่นใหม่แจกลูกค้า`,
                    message: `ค้นหาประวัติโปรโมชั่นใหม่แจกลูกค้าไม่สำเร็จ ${e.message}`,
                }
            } );
        }
    }

    useEffect(()=>{
        void getNewCustomerPromotionList();
    },[])

    return(
        <>
        <Form key={"NewCustomerPromotionList"} noValidate onSubmit={handleSubmitNewCustomerPromotionList(submitSearchNewCustomerBonusHistory)}>
            <Form.Row>
                <Form.Group as={Col} md="6" lg="6">
                    <Form.Label>เลือกประเภทโบนัสที่ต้องการค้นหา</Form.Label>
                    <InputGroup>
                        <Form.Control
                            as="select"
                            name="new_customer_promotion"
                            id="new_customer_promotion"
                            custom
                            isInvalid={!!errorsNewCustomerPromotionList.new_customer_promotion}
                            ref={registerNewCustomerPromotionList({})}
                            defaultValue={""}
                            // onChange={(e) => setSelectBank(e.target.value)}
                        >
                            <option value="">กรุณาเลือกประเภทโบนัสลูกค้าใหม่</option>
                            {
                                newCustomerPromotionList?.map(({ id, name }, index) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </Form.Control>
                        <InputGroup.Append><Button type={"submit"}>ค้นหา</Button></InputGroup.Append>
                        <Form.Control.Feedback type="invalid" tooltip>
                            กรุณาเลือกประเภทโบนัสลูกค้าใหม่
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3">
                    <Form.Label>ลูกค้ารับไปแล้ว(คน)</Form.Label>
                    <InputGroup>
                        <Form.Control
                            as="input"
                            name="total_customers"
                            id="total_customers"
                            readOnly
                            defaultValue={0}
                            ref={registerNewCustomerPromotionList}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="3" lg="3">
                    <Form.Label>รวมเป็นจำนวนเครดิตทั้งหมด(บาท)</Form.Label>
                    <InputGroup>
                        <Form.Control
                            as="input"
                            name="total_amount"
                            id="total_amount"
                            readOnly
                            defaultValue={0}
                            ref={registerNewCustomerPromotionList}
                        />
                    </InputGroup>
                </Form.Group>
            </Form.Row>
        </Form>

        <Table>
            <thead>
                <tr>
                    <th>User ID</th>
                    <th>ชื่อ - นามสกุล</th>
                    <th>ทำรายการเมื่อ</th>
                </tr>
            </thead>
            <tbody>
            {
                newCustomerBonusHistoryList?.map(data=>{
                    return (
                        <tr>
                            <td>{data.user_id}</td>
                            <td>{data.first_name} - {data.last_name}</td>
                            <td>{moment(data.created_at).format("DD/MM/YYYY HH:mm")}</td>
                        </tr>
                    )
                })
            }

            </tbody>
        </Table>

        </>
    )
}