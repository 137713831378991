import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import luckydraw_api from "../../api/luckydraw";
import LuckyDrawStats from "./LuckyDrawStats";


export default function ModalLuckyDrawStats ( props ) {
    const { luckyDrawStatsData, isOpenModal, onClose } = props;

    const [ luckyDrawStats, setLuckyDrawStats ] = useState( {} );

    useEffect( () => {
        async function searchLuckyDrawStats () {
            try {
                const response = await luckydraw_api.SEARCH_LUCKYDRAW_STATS( { id: luckyDrawStatsData.id } )
                setLuckyDrawStats( response.data.data )
            } catch ( err ) {
                console.log( err )
            }
        }

        if ( luckyDrawStatsData.id !== '' && luckyDrawStatsData.id !== undefined ) {
            void searchLuckyDrawStats()
        }
    }, [ luckyDrawStatsData ] )

    return (
        <>
            <Modal show={isOpenModal} size="xl">
                <Modal.Header>
                    <Modal.Title>สถิติเกมชิงโชค: {luckyDrawStatsData.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LuckyDrawStats data={luckyDrawStats} id={luckyDrawStatsData.id} onClose={onClose}/>
                </Modal.Body>
            </Modal>
        </>
    )
}