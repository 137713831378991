import React from "react";
import styled from 'styled-components'
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import { ReactComponent as BBL } from "../../banks-logo/th/bbl.svg";
import { ReactComponent as KBANK } from "../../banks-logo/th/kbank.svg";
import { ReactComponent as RBS } from "../../banks-logo/th/rbs.svg";
import { ReactComponent as KTB } from "../../banks-logo/th/ktb.svg";
import { ReactComponent as JPM } from "../../banks-logo/th/jpm.svg";
import { ReactComponent as MUFG } from "../../banks-logo/th/mufg.svg";
import { ReactComponent as TMB } from "../../banks-logo/th/tmb.svg";
import { ReactComponent as SCB } from "../../banks-logo/th/scb.svg";
import { ReactComponent as CITI } from "../../banks-logo/th/citi.svg";
import { ReactComponent as SMBC } from "../../banks-logo/th/smbc.svg";
import { ReactComponent as SC } from "../../banks-logo/th/sc.svg";
import { ReactComponent as CIMB } from "../../banks-logo/th/cimb.svg";
import { ReactComponent as UOB } from "../../banks-logo/th/uob.svg";
import { ReactComponent as BAY } from "../../banks-logo/th/bay.svg";
import { ReactComponent as MEGA } from "../../banks-logo/th/mega.svg";
import { ReactComponent as BOA } from "../../banks-logo/th/boa.svg";
import { ReactComponent as CACIB } from "../../banks-logo/th/cacib.svg";
import { ReactComponent as GSB } from "../../banks-logo/th/gsb.svg";
import { ReactComponent as HSBC } from "../../banks-logo/th/hsbc.svg";
import { ReactComponent as DB } from "../../banks-logo/th/db.svg";
import { ReactComponent as GHB } from "../../banks-logo/th/ghb.svg";
import { ReactComponent as BAAC } from "../../banks-logo/th/baac.svg";
import { ReactComponent as MB } from "../../banks-logo/th/mb.svg";
import { ReactComponent as BNP } from "../../banks-logo/th/bnp.svg";
import { ReactComponent as TBANK } from "../../banks-logo/th/tbank.svg";
import { ReactComponent as IBANK } from "../../banks-logo/th/ibank.svg";
import { ReactComponent as TISCO } from "../../banks-logo/th/tisco.svg";
import { ReactComponent as KK } from "../../banks-logo/th/kk.svg";
import { ReactComponent as ICBC } from "../../banks-logo/th/icbc.svg";
import { ReactComponent as TCRB } from "../../banks-logo/th/tcrb.svg";
import { ReactComponent as LHB } from "../../banks-logo/th/lhb.svg";
import { ReactComponent as TTB } from "../../banks-logo/th/ttb.svg";
import TrueWallet from "../../banks-logo/th/truewallet.svg"
import VIZ from "../../banks-logo/th/v_pay_logo.svg"
import COREPAY from "../../banks-logo/th/corepay_logo.png"
import HENGPAY from "../../banks-logo/th/hengpay_logo.svg"
import PERFECTPAYS from "../../banks-logo/th/perfectpays.svg"
import PAYONEX from "../../banks-logo/th/payonex_logo.svg"
import WEALTHWAVE from "../../banks-logo/th/wealthwave-ws.svg"
import HENGPAY68 from "../../banks-logo/th/hp68_logo.svg"
import E2P from "../../banks-logo/th/e2p_logo.svg"
import PAYONE from "../../banks-logo/th/payone_x2.svg"
import JOMPAY from "../../banks-logo/th/jompay_logo.png"
import NOTFOUND from "../../banks-logo/th/not_found.svg"

const bankFile = require( '../../banks-logo/banks.json' )
const bankLogoMapping = bankFile.th

const Layout = styled.div`
  display: flex;
`
const Logo = styled.div`
  background-color: ${props => props.color};
  padding: 0.2em;
  margin-right: 10px;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  align-self: center;

`

export const LogoWithAccountNo = ( props ) => {
    const { bankShortName, bankName, accountNo, workOrderType, workOrderSubType, column } = props
    // console.log("LogoWithAccountNo props :", props)

    if ( !bankLogoMapping[ bankShortName ] ) {
        return (
            <>
                {bankName || "-"}/{accountNo || "-"}
            </>
        )
    }


    let overlayField = bankName
    let layoutField = accountNo

    if ( column === "1" && !workOrderSubType ) {
        if ( workOrderType === "withdraw" ) {
            overlayField = accountNo
            layoutField = bankName
        }
    }

    if ( column === "2" && !workOrderSubType ) {
        if ( workOrderType === "deposit" ) {
            overlayField = accountNo
            layoutField = bankName
        }
    }

    // return (
    //   <OverlayTrigger
    //     placement='top'
    //     overlay={
    //       bankName ?
    //         <Tooltip id={`tooltip-status`}>
    //           {bankName}
    //         </Tooltip> : <div>
    //         </div>
    //     }
    //   >
    //     <Layout>
    //       <SwitchLogo bankShortName={bankShortName} />
    //       {accountNo}
    //     </Layout>
    //   </OverlayTrigger>
    // )

    return (
        <OverlayTrigger
            placement='top'
            overlay={
                overlayField ?
                    <Tooltip id={`tooltip-status`}>
                        {overlayField}
                    </Tooltip> : <div>
                    </div>
            }
        >
            <Layout>
                <SwitchLogo bankShortName={bankShortName}/>
                <div className={"text-left"}>
                    <b className={"text-sm text-uppercase"}>{bankShortName}</b><br/>
                    <b className={"text-sm"}>{layoutField}</b>
                </div>
            </Layout>
        </OverlayTrigger>
    )


}
export const LogoWithAccountNo2 = ( props ) => {
    const { bankShortName, bankFullName, bankName, accountNo, workOrderType, workOrderSubType, column } = props
    // console.log("LogoWithAccountNo col ", column, "props", props)

    if ( !bankLogoMapping[ bankShortName ] ) {
        return (
            <>
                {bankName || "-"}/{accountNo || "-"}
            </>
        )
    }

    let overlayField = bankName
    let layoutField = accountNo


    if ( workOrderType === "deposit") {
        overlayField = bankFullName
    }

    overlayField = overlayField.replace('จำกัด','')
    overlayField = overlayField.replace('(มหาชน)','')
    overlayField = overlayField.replaceAll('ธนาคาร','ธ.')

    const overlayTooltip = (bankName)=>{
        if (bankName){
            return (<Tooltip id={`tooltip-status`}>{bankName}</Tooltip>)
        }else{
            return (<div/>)
        }
    }

    return (
        <OverlayTrigger
            placement='top'
            overlay={overlayTooltip(bankName)}
        >
            <Row className={"flex justify-center"}>
                <SwitchLogo bankShortName={bankShortName}/>

                <div style={{width: "10rem"}}>
                    <p className="text-bold text-sm">{overlayField}</p>
                    <p className="text-info text-bold">{layoutField}</p>
                </div>
            </Row>
        </OverlayTrigger>
    )
}

export const LogoWithAccountNoSimple = ( props ) => {
    // console.log("props: ", props)
    const { bankShortName, bankName, accountNo } = props

    if ( !bankLogoMapping[ bankShortName ] ) {
        return (
            <>
                {bankName || "-"}/{accountNo || "-"}
            </>
        )
    }

    let overlayField = bankName.replace('จำกัด','')
    overlayField = overlayField.replace('(มหาชน)','')
    overlayField = overlayField.replaceAll('ธนาคาร','ธ.')
    let layoutField = accountNo

    return (
        <div className={"flex mr-4"}>
            <SwitchLogo bankShortName={bankShortName}/>
            <div className="text-left">
                <p className="text-bold text-sm">{overlayField}</p>
                <p className="text-blue text-bold">{layoutField}</p>
            </div>
        </div>
    )
}

const SwitchLogo = ( props ) => {
    const { bankShortName } = props
    // console.log("bankShortName: ", bankShortName)
    switch ( bankShortName ) {
        case "bbl":
            return (
                <Logo color={bankLogoMapping.bbl.color}>
                    <BBL/>
                </Logo>
            )
        case "kbank":
            return (
                <Logo color={bankLogoMapping.kbank.color}>
                    <KBANK/>
                </Logo>
            )
        case "rbs":
            return (
                <Logo color={bankLogoMapping.rbs.color}>
                    <RBS/>
                </Logo>
            )
        case "ktb":
            return (
                <Logo color={bankLogoMapping.ktb.color}>
                    <KTB/>
                </Logo>
            )
        case "jpmc":
            return (
                <Logo color={bankLogoMapping.jpmc.color}>
                    <JPM/>
                </Logo>
            )
        case "mufg":
            return (
                <Logo color={bankLogoMapping.mufg.color}>
                    <MUFG/>
                </Logo>
            )
        case "tmb":
            return (
                <Logo color={bankLogoMapping.tmb.color}>
                    <TMB/>
                </Logo>
            )
        case "ttb":
            return (
                <Logo color={bankLogoMapping.ttb.color}>
                    <TTB style={{ paddingTop: "0.3em" }}/>
                </Logo>
            )
        case "scb":
            return (
                <Logo color={bankLogoMapping.scb.color}>
                    <SCB/>
                </Logo>
            )
        case "citi":
            return (
                <Logo color={bankLogoMapping.citi.color}>
                    <CITI/>
                </Logo>
            )
        case "smbc":
            return (
                <Logo color={bankLogoMapping.smbc.color}>
                    <SMBC/>
                </Logo>
            )
        case "scbt":
            return (
                <Logo color={bankLogoMapping.scbt.color}>
                    <SC/>
                </Logo>
            )
        case "cimbt":
            return (
                <Logo color={bankLogoMapping.cimbt.color}>
                    <CIMB/>
                </Logo>
            )
        case "bay":
            return (
                <Logo color={bankLogoMapping.bay.color}>
                    <BAY/>
                </Logo>
            )
        case "uobt":
            return (
                <Logo color={bankLogoMapping.uobt.color}>
                    <UOB/>
                </Logo>
            )
        case "mega":
            return (
                <Logo color={bankLogoMapping.mega.color}>
                    <MEGA/>
                </Logo>
            )
        case "ba":
            return (
                <Logo color={bankLogoMapping.ba.color}>
                    <BOA/>
                </Logo>
            )
        case "cacib":
            return (
                <Logo color={bankLogoMapping.cacib.color}>
                    <CACIB/>
                </Logo>
            )
        case "gsb":
            return (
                <Logo color={bankLogoMapping.gsb.color}>
                    <GSB/>
                </Logo>
            )
        case "hscb":
            return (
                <Logo color={bankLogoMapping.hscb.color}>
                    <HSBC/>
                </Logo>
            )
        case "deutsche":
            return (
                <Logo color={bankLogoMapping.deutsche.color}>
                    <DB/>
                </Logo>
            )
        case "ghb":
            return (
                <Logo color={bankLogoMapping.ghb.color}>
                    <GHB/>
                </Logo>
            )
        case "baac":
            return (
                <Logo color={bankLogoMapping.baac.color}>
                    <BAAC/>
                </Logo>
            )
        case "mhcb":
            return (
                <Logo color={bankLogoMapping.mhcb.color}>
                    <MB/>
                </Logo>
            )
        case "bnp":
            return (
                <Logo color={bankLogoMapping.bnp.color}>
                    <BNP/>
                </Logo>
            )
        case "tbank":
            return (
                <Logo color={bankLogoMapping.tbank.color}>
                    <TBANK/>
                </Logo>
            )
        case "isbt":
            return (
                <Logo color={bankLogoMapping.isbt.color}>
                    <IBANK/>
                </Logo>
            )
        case "tisco":
            return (
                <Logo color={bankLogoMapping.tisco.color}>
                    <TISCO/>
                </Logo>
            )
        case "kk":
            return (
                <Logo color={bankLogoMapping.kk.color}>
                    <KK/>
                </Logo>
            )
        case "icbct":
            return (
                <Logo color={bankLogoMapping.icbct.color}>
                    <ICBC/>
                </Logo>
            )
        case "tcd":
            return (
                <Logo color={bankLogoMapping.tcd.color}>
                    <TCRB/>
                </Logo>
            )
        case "lh":
            return (
                <Logo color={bankLogoMapping.lh.color}>
                    <LHB/>
                </Logo>
            )
        case "truewallet":
            return (
                <Logo style={{ width: "32px" }} color={bankLogoMapping.truewallet.color}>
                    <div style={{ height: "25px", width: "25px" }}>
                        <img
                            src={TrueWallet}
                            alt="website logo"
                        />
                    </div>
                </Logo>
            )
        case "viz":
            return (
                <Logo style={{ width: "40px" }} color={bankLogoMapping.viz.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                        <img
                            src={VIZ}
                            width={"40px"}
                            alt="website logo"
                        />
                    {/*</div>*/}
                </Logo>

            )
        case "cp":
            return (
                <Logo style={{ width: "40px" }} color={bankLogoMapping.cp.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={COREPAY}
                        width={"40px"}
                        alt="website logo"
                        className={"d-inline"}
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "hengpay":
            return (
                <Logo style={{ width: "40px" }} color={bankLogoMapping.hengpay.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={HENGPAY}
                        width={"40px"}
                        alt="website logo"
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "perfectpays":
            return (
                <Logo style={{ width: "40px" }} color={bankLogoMapping.perfectpays.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={PERFECTPAYS}
                        width={"40px"}
                        alt="website logo"
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "payonex":
            return (
                <Logo style={{ width: "auto", display:"flex" }} color={bankLogoMapping.payonex.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={PAYONEX}
                        width={"40px"}
                        alt="payonex logo"
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "wealthwave":
            return (
                <Logo style={{ width: "auto", display:"flex" }} color={bankLogoMapping.payonex.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={WEALTHWAVE}
                        width={"40px"}
                        alt="wealthwave logo"
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "hengpay68":
            return (
                <Logo style={{ width: "auto", display:"flex" }} color={bankLogoMapping.hengpay68.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={HENGPAY68}
                        width={"40px"}
                        alt="hengpay68 logo"
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "e2p":
            return (
                <Logo style={{ width: "auto", display:"flex" }} color={bankLogoMapping.e2p.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={E2P}
                        width={"40px"}
                        alt="e2p logo"
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "payone":
            return (
                <Logo style={{ width: "auto", display:"flex" }} color={bankLogoMapping.payone.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={PAYONE}
                        width={"40px"}
                        alt="payone logo"
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "jompay":
            return (
                <Logo style={{ width: "auto", display:"flex" }} color={bankLogoMapping.jompay.color}>
                    {/*<div style={{ height: "45px", width: "45px" }}>*/}
                    <img
                        src={JOMPAY}
                        width={"40px"}
                        alt="jompay logo"
                    />
                    {/*</div>*/}
                </Logo>

            )
        case "not_found":
            return (
                <Logo color={bankLogoMapping.not_found.color}>
                    <img
                        src={NOTFOUND}
                        width={"40px"}
                        alt="not found logo"
                    />
                </Logo>
            )
        default:
            return (
                <></>
            )
    }
}

